function secondsToMS(SECONDS) {
  let dt = new Date(SECONDS * 1000).toISOString().slice(14, 19)
  return dt;
}
function miliSecondsToMS(ms) {
  let dt = new Date(ms).toISOString().slice(14, 19)
  return dt;
}


var banArr = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯",];
function translateToBanglaNum(timeArr) {
  let resultStr = ``;
  for (let singleLetter of timeArr) {
    if (!isNaN(parseInt(singleLetter))) {
      resultStr += banArr[parseInt(singleLetter)]
    } else {
      resultStr += singleLetter
    }
  }
  return resultStr;
}

function colorCleanUp(className) {
  let elements = document.getElementsByClassName(className)
  for (let element of elements) {
    element.classList.remove("bg_right")
    element.classList.remove("bg_wrong")
  }
}

function translateToBanglaNumOnlyDigit(number) {
  return number.toString().replace(/\d/g, (d) => banArr[d]);
}

function getTimeInBangla(time) {
  // Ensure time is in XX:XX format
  if (!time) return '';
  const formattedTime = time.padStart(5, '0'); // Ensures 2:45 becomes 02:45
  return translateToBanglaNum(formattedTime);
}


export { translateToBanglaNum,secondsToMS,miliSecondsToMS,colorCleanUp,getTimeInBangla,translateToBanglaNumOnlyDigit }