import React, { useContext, useEffect, useState } from "react";

import styles from "./BannerPage.module.css";
import "./BannerPart3.css";
import axios from "axios";
import { baseUrl, dataContext, portal } from "../../App";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import activityLogSubmission from "../../helper/activitylog";
import RotatingBox from "./RotatingBox";
import analyticEvent from "../../helper/gaEvent";
import SliderBanner from "./SliderBanner";
import logoImage from './img/logo.png'
import playBtnImage from './img/settings.png'
import banglaImage from './img/bangla.png'
import englishImage from './img/english.png'
import { BsFillInfoCircleFill } from "react-icons/bs";
import PromotionalBanner from "./PromotionalBanner/PromotionalBanner";
import PlayerStatusCard from "./PlayerStatusCard/PlayerStatusCard";
import PlayingHistory from "./PlayingHistory/PlayingHistory";

const BannerPart3 = ({ props }) => {
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents, extraData, setExtraData } =
    useContext(dataContext);
  const { quizClickHandlerInLogin, setActiveBottom, setShowGameSelectionModal, setShowSpellingInfoModal } = props;

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
    analyticEvent('home', 'home-view')
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      <div className={styles.bannerPageBody}>
        <div className={styles.backgroundWrapper}>
          <PlayerStatusCard data={playerData} />
          <div className={styles.gameCorner}>
            <div className={styles.gamesTitle}>
              <img src={require('./img/gamesIcon.png')} alt="" className={styles.gamesTitleImg} />
              <span>গেমস খেলে পুরস্কার জিতুন</span>
            </div>
            <div className={styles.gamesList}>
              <div className={styles.gameItem} onClick={() => setShowGameSelectionModal(true)} style={{backgroundImage: `url(${require('./img/bg1.png')})`}}>
                {/* <img src={require('./img/wordlyIcon.png')} alt="" className={styles.gameItemImg} />
                <div className={styles.gameItemTitle}>ওয়ার্ডলি</div>
                <button className={styles.playButton}>খেলুন <FaArrowRight /></button> */}
              </div>
              <div className={styles.gameItem} onClick={() => quizClickHandlerInLogin(134, playerData)} style={{backgroundImage: `url(${require('./img/bg2.png')})`}}>
                  <BsFillInfoCircleFill className={styles.infoIcon} onClick={(e) => {e.stopPropagation(); setShowSpellingInfoModal(true)}}/>
              </div>
            </div>
          </div>
        </div>

        <PromotionalBanner bannerSrc={`https://ms.quizard.live/bkash-quiz-banner/promotional-banner/wordly/banner1.png`} handleClick={() => { setActiveBottom('referral'); activityLogSubmission('wordly_banner_1'); }} />
        <PromotionalBanner bannerSrc={`https://ms.quizard.live/bkash-quiz-banner/promotional-banner/wordly/banner2.png`} handleClick={() => { window.open('https://www.quizard.live/?qcid=115','_blank'); activityLogSubmission('quizard_from_wordly_banner'); }} />
        <PlayingHistory events={events} playerData={playerData} quizClickHandler={quizClickHandlerInLogin} />
      </div>
    </>
  );
};

export default BannerPart3;
