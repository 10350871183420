import React, { useContext, useEffect, useState } from 'react'
import styles from './Subscription.module.css'
import SubscriptionStatus from './SubscriptionStatus/SubscriptionStatus'
import { dataContext } from '../../App'
import PaymentHistory from './PaymentHistory/PaymentHistory'
import activityLogSubmission from '../../helper/activitylog'

const Subscription = ({ quizClickHandler }) => {
  const [activeTab, setActiveTab] = useState('subscription')
  const { playerData } = useContext(dataContext);
  useEffect(() => {
    activityLogSubmission("subscription");
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mainNav}>
        <button
          className={`${styles.mainNavButton} ${activeTab === 'subscription' ? styles.mainNavActive : ''}`}
          onClick={() => setActiveTab('subscription')}
        >
          সাবস্ক্রিপশন
        </button>
        <button
          className={`${styles.mainNavButton} ${activeTab === 'payment' ? styles.mainNavActive : ''}`}
          onClick={() => setActiveTab('payment')}
        >
          পেমেন্ট হিস্টোরি
        </button>
      </div>
      {activeTab === 'subscription' && <SubscriptionStatus isSubscribe={playerData?.isSubscribe} isSubscribeTournament={playerData?.isSubscribeTournament} quizClickHandler={quizClickHandler} />}
      {activeTab === 'payment' && <PaymentHistory />}
    </div>
  )
}

export default Subscription