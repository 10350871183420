import React, { useContext, useEffect, useState } from 'react'
import './QuizPage.css'
import { baseUrl, dataContext, portal } from '../../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import BlockRound from './BlockRound/BlockRound';
import ResultModal from './ResultModal/ResultModal';
import mixUpImg from "./bgimage/mixUpBg.png";
import wordlyImg from "./bgimage/wordly.png";
import spellingBeeImg from "./bgimage/spellingBee.png";
import QuestionBodyBoishakh from './QuestionBodyBoishakh/QuestionBodyBoishakh';
import QuestionBodySpelling from './QuestionBodySpelling/QuestionBodySpelling';

const QuizPage = () => {
  const navigate = useNavigate()
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = parseInt(searchParams.get("eventId"))
  const [eventDetails, setEventDetails] = useState({})
  const [showQuestions, setShowQuestions] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showResultModal, setShowResultModal] = useState(false)
  const [finalScore, setFinalScore] = useState(0)
  const [playerRound, setPlayerRound] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [playedRoundNumber, setPlayedRoundNumber] = useState(0)

  useEffect(() => {
    if (token && eventId) {
      axios.get(`${baseUrl}/api/clients/participant_profiles/?portal=${portal}`, {
        headers: { Authorization: `Token ${token}` }
      })
        .then(response => {
          setPlayerData(response.data[0])
          let data = response.data[0];
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          if (!data?.isSubscribe) {
            navigate('../')
          }else{
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
            })
          }
        }).catch(res => {
          localStorage.setItem("AuthToken", "");
          setPlayerData({});
          setToken(null);
          navigate('../')
        });
    }else{
      navigate('../')
    }

  }, [])
  useEffect(() => {
    if (isLoadingEnd) {
      let playerLeaderboardArr = playerData?.event_history;
      let playerRoundNum = 0;
      let playerRoundTotal = 0;
      let playerLeaderboard = []

      if (playerLeaderboardArr) { playerLeaderboard = playerLeaderboardArr.filter((a) => { if (a.event_id == eventDetails.id) { return a } }); }
      playerRoundTotal = (playerLeaderboard.length) ? playerLeaderboard[0]?.round_number__max : 0;
      setPlayerRound(playerRoundTotal);
      
      let eventRound = eventDetails?.round
      if(parseInt(eventId) === 67 || parseInt(eventId) === 79){
        eventRound = 2;
      playerRoundTotal = playerData?.round_play?.round || 0;
      setPlayedRoundNumber(playerRoundTotal);
      }

      sessionStorage.setItem('leaderEventId', eventDetails?.id)
      if (playerRoundTotal < eventRound) {
        setShowQuestions(true)
      } else {
        (playerData.msisdn === '1867125111'
          || playerData.msisdn === '1738246580'
          || playerData.msisdn === '1711082515'
        )?setShowQuestions(true):setShowBlockModal(true)
      }
    }
  }, [isLoadingEnd])
  let isWordly = parseInt(eventId) === 67 || parseInt(eventId) === 79;
  let isSpellingBee = parseInt(eventId) === 134;
  let backgroundImage = wordlyImg;
  if(isSpellingBee){backgroundImage = spellingBeeImg}

  return (
    <div className='quizPageBody' style={{ background: `linear-gradient(45deg, black, #0083B0)` }}>
      <img src={backgroundImage} alt="" style={{width: '100%',position: 'absolute',bottom: 0,left: 0,zIndex: -1}} />
      {(showQuestions && isSpellingBee) && (<QuestionBodySpelling 
        eventDetails={eventDetails} 
        finalScore={finalScore} 
        setFinalScore={setFinalScore} 
        setShowResultModal={setShowResultModal} 
        playerRound={playerRound}
      />)}
      {(showQuestions && isWordly) && (<QuestionBodyBoishakh 
        eventDetails={eventDetails} 
        finalScore={finalScore} 
        setFinalScore={setFinalScore} 
        setShowResultModal={setShowResultModal} 
        playerRound={playerRound}
      />)}
      {showBlockModal && (<BlockRound 
        playedRoundNumber={playedRoundNumber} 
        showBlockModal={showBlockModal} 
        setShowBlockModal={setShowBlockModal}
      />)}
      {showResultModal && (<ResultModal 
        eventDetails={eventDetails} 
        showResultModal={showResultModal} 
        setShowResultModal={setShowResultModal} 
        finalScore={finalScore}
      />)}
    </div>
  )
}

export default QuizPage