import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign.module.css";
import LoginModalCampaignOnlyDiv from "../../components/LoginModalCampaign/LoginModalCampaignOnlyDiv";

const CampaignOnlyLoginDiv = ({campaignNumber}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { playerData } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);

  const campaignId = searchParams.get("qcid");
  useEffect(() => {
    // Redirect if no campaign ID
    if (!campaignId) {
      navigate("../");
      return;
    }
    sessionStorage.setItem('cid', campaignId);

    // Handle login modal
    const timer = setTimeout(() => {
      setShowLoginModalCampaign(true);
    }, 2500);

    // activity logs
    const msisdn = playerData?.msisdn ?? null;
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission(`campaign${campaignNumber}`, msisdn);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className={styles.landingPage}>
      <img
        src={`https://ms.quizard.live/bkash-quiz-banner/camp-banner/wordly/camp${campaignNumber}.jpg`}
        alt="Campaign Banner"
        loading="lazy"
        className={styles.landingImg}
      />
      {showLoginModalCampaign && (
        <LoginModalCampaignOnlyDiv
        showLoginModalCampaign={showLoginModalCampaign}
        setShowLoginModalCampaign={setShowLoginModalCampaign}
        activeBottom={activeBottom}
        setActiveBottom={setActiveBottom}
      />)}
        <button 
          className={styles.tcButton} 
          onClick={() => navigate(`../?page=tc&qcid=${campaignId}`)}
        >
          Terms & Conditions
        </button>
    </div>
  );
};

export default CampaignOnlyLoginDiv;
