import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign.module.css";

const Campaign = ({campaignNumber}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { playerData } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);

  useEffect(() => {
    // Redirect if no campaign ID
    const campaignId = searchParams.get("qcid");
    if (!campaignId) {
      navigate("../");
      return;
    }
    sessionStorage.setItem('cid', campaignId);

    // Handle login modal
    const timer = setTimeout(() => {
      setShowLoginModalCampaign(true);
    }, 2500);

    // activity logs
    const msisdn = playerData?.msisdn ?? null;
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission(`campaign${campaignNumber}`, msisdn);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className={styles.landingPage}>
      <img
        src={`https://ms.quizard.live/bkash-quiz-banner/camp-banner/wordly/camp${campaignNumber}.jpg`}
        loading="lazy"
        alt="Campaign Banner"
        className={styles.landingImg}
      />
      <LoginModalCampaign
        showLoginModalCampaign={showLoginModalCampaign}
        setShowLoginModalCampaign={setShowLoginModalCampaign}
        activeBottom={activeBottom}
        setActiveBottom={setActiveBottom}
      />
    </div>
  );
};

export default Campaign;
