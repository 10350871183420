import React, { useState, useEffect, useContext } from 'react';
import paymentHistoryData from './dummy_data.json';
import styles from './PaymentHistory.module.css';
import { formatDate } from '../../Profile/ReferralHistory/dateFormatter';
import { convertToBengaliNumber } from '../../BannerPart3/helper';
import { fetchPaymentHistory } from '../../../services';
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';

const PaymentHistory = () => {
  const {token} = useContext(dataContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    activityLogSubmission("payment_history");
  }, []);

  useEffect(() => {
    fetchPaymentHistory(token).then((res) => {
      setData(res);
    });
  }, [token]);

  return (
    <div className={styles.paymentHistory}>
      <div className={styles.title}>পেমেন্ট হিস্টরি</div>
      <div className={styles.historyList}>
        {[...data].reverse().map((payment) => (
          <div key={payment.id} className={styles.historyItem}>
            <div className={styles.paymentInfo}>
              <div className={styles.subsType}>{payment.serviceType === 'Daily' ? 'Wordly সাবস্ক্রিপশন' : 
                 payment.serviceType === 'Tournament' ? 'টুর্নামেন্ট' : 
                 payment.serviceType}</div>
              <div className={styles.amount}>৳{convertToBengaliNumber(payment.amount)}</div>
            </div>
            <div className={styles.paymentAmount}>
              <div className={styles.dateTime}>{formatDate(payment.paymentDate)}</div>
              <span className={styles.paymentDuration}>{convertToBengaliNumber(payment.duration)} দিনের জন্য</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentHistory;