import { useNavigate } from 'react-router-dom'
import styles from './PopUpMenu.module.css'
import bgImg from './bg.png'
import homeImg from './home.png'
import leaderboardImg from './leaderboard.png'
import resumeImg from './resume.png'

const PopUpMenu = ({ setShowPopUp, eventDetails }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.popUpMenu}>
      <div className={styles.popUpMenuContainer}>
        <img src={bgImg} alt="bg" className={styles.bgImg} />
        <img src={homeImg} alt="home" className={styles.homeImg} onClick={()=>{navigate(`../?page=home`, { replace: true });}} />
        <img src={resumeImg} alt="resume" className={styles.resumeImg} onClick={() => setShowPopUp(false)} />
        <img src={leaderboardImg} alt="leaderboard" className={styles.leaderboardImg} onClick={()=>{navigate(`../?page=leader`, { replace: true });}} />
        <div className={styles.closeBtn} onClick={() => setShowPopUp(false)}></div>
      </div>

    </div>
  )
}

export default PopUpMenu