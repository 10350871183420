import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { baseUrl, dataContext, portal } from '../../../App'
import { useElapsedTime } from 'use-elapsed-time'
import { colorCleanUp, secondsToMS, translateToBanglaNum } from './questionHelper'
import activityLogSubmission from '../../../helper/activitylog'
import { useNavigate } from 'react-router-dom';
import SpellingGamePlay from './SpellingGamePlay/SpellingGamePlay'
import PopUpMenu from '../PopUpMenu/PopUpMenu'
import rightAudio from '../../../assets/sound/right.wav'
import wrongAudio from '../../../assets/sound/wrong2.mp3'
import timesUpAudio from '../../../assets/sound/timesUp.mp3'
import timerAudio from '../../../assets/sound/timer.mp3'


const QuestionBodySpelling = ({ eventDetails, finalScore, setFinalScore, setShowResultModal, playerRound }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  const [allQuestions, setAllQuestions] = useState([])
  const [currentQues, setCurrentQues] = useState({})
  const [currentQuesIndex, setCurrentQuesIndex] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [timerWidth, setTimerWidth] = useState(100)
  const [timerTime, setTimerTime] = useState(eventDetails.allocated_time)
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const { elapsedTime } = useElapsedTime({ isPlaying });
  const navigate = useNavigate();

  const tickingElm = useRef(0);
  useEffect(() => {
    axios.get(`${baseUrl}/api/questions/?rules=${eventDetails.id}&portal=${portal}`, {
      headers: { Authorization: `Token ${token}` }
    })
      .then(response => {
        setAllQuestions(response.data)
        console.log(response.data)
        setIsLoadingEnd(true)
        setCurrentQuesIndex(0)
        tickingElm.current = new Audio(timerAudio)
        tickingElm.current.volume = 0.5;
        tickingElm.current.play()
        tickingElm.current.loop = true;
      })
  }, [])

  let remainingWidth = (((timerTime - elapsedTime) / timerTime) * 100).toFixed(2);
  let remainingTime = (((timerTime - elapsedTime))).toFixed(2);

  useEffect(() => {
    setTimerWidth(remainingWidth)
  }, [remainingWidth])

  if (elapsedTime > timerTime) {
    if (isPlaying) {
      setIsPlaying(false)
      gameDone()
    }
  }



  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission(`events-${eventDetails.id}`, msisdn);
    return () => {
      // cleanup function
      console.log("in cleanup")
    }
  }, [])

  // Loading: [Done]
  useEffect(() => {
    if (isLoadingEnd) {
      console.log(currentQues)
      if(allQuestions.length <= 0){
        navigate('../',{replace:true})
      }else{
        setIsPlaying(true)
      }
    }
  }, [currentQuesIndex])



  function gameDone() {
    sessionStorage.setItem('quizPlay_eventId', eventDetails.id);
    sessionStorage.setItem('quizPlay_passingScore', eventDetails.passing_score);
    setIsPlaying(false)
    setShowResultModal(true)
    navigate(`../?page=result&eventId=${eventDetails.id}`)
  }
  useEffect(() => {
    return () => {
      tickingElm.current.pause()
      console.log("in cleanup")
    }
  }, [])

  function insertData(isRight, mobile, round, questionId, selectedAns, category, eventId) {
    // insert Data Function
    // console.log(isRight, mobile, round, questionId, selectedAns, category, eventId)
    axios.post(`${baseUrl}/api/participant/`, {
      is_right: isRight,
      msisdn: mobile,
      round_number: round,
      question: questionId,
      selected_answer: selectedAns,
      category: category,
      event: eventId,
      portal: portal
    }, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  return (
    <>
      {/* {allQuestions?.length && <MixUp props={{ insertData, playerData, playerRound, eventDetails, setFinalScore, setShowResultModal, gameDone,allQuestions,remainingTime,timerWidth }} />} */}
      {/* <>Hello from spelling</> */}
      {(allQuestions?.length > 0) && <SpellingGamePlay props={{ insertData, playerData, playerRound, eventDetails, setFinalScore, setShowResultModal, gameDone,allQuestions,remainingTime,timerWidth,setShowPopUp,rightAudio,wrongAudio,wrongAudio }} />}
      {showPopUp && <PopUpMenu setShowPopUp={setShowPopUp} eventDetails={eventDetails} />}
    </>
  )
}

export default QuestionBodySpelling