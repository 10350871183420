import React from 'react'
import styles from './PlayingHistory.module.css'
import { convertToBengaliNumber } from '../helper';

const PlayingHistory = ({ events, playerData, quizClickHandler }) => {
  if (!playerData?.id) return <></>;
  let playingHistory = playerData?.leaderboard;

  // Filter eligible history items first
  const eligibleHistory = playingHistory?.filter(history => 
    history.round_number !== 0 && 
    events?.some(event => event.id === history.event_id)
  );

  if (!eligibleHistory?.length) return <></>;

  return (
    <div className={styles.playingHistoryContainer}>
      <h3 className={styles.historyTitle}>সম্প্রতি খেলেছেন</h3>
      <div className={styles.historyList}>
        {eligibleHistory.map((history, index) => {
          const matchingEvent = events?.find(event => event.id === history.event_id);
          if(matchingEvent.id === 67 || matchingEvent.id === 79){
          const scorePercentage = (history.score / parseInt(matchingEvent.question_set)) * 100;
          
          return (
            <div key={index} className={styles.historyItemContainer} style={{ backgroundColor: index % 2 === 0 ? '#D8EDFF' : '#D1FFED' }}>
              <div className={styles.historyItem}>
                <div className={styles.leftSection}>
                  <div className={styles.quizInfo}>
                    <h4>
                      {matchingEvent.id === 67 || matchingEvent.id === 79 
                        ? `Wordly: ${matchingEvent.events}` 
                        : matchingEvent.events}
                    </h4>
                    <p>স্কোর: <span className={styles.scoreText}>{convertToBengaliNumber(Math.round(history.score))}</span> / {convertToBengaliNumber(parseInt(matchingEvent.question_set))}</p>
                  </div>
                </div>

                <button
                  className={styles.playAgainBtn}
                  onClick={() => quizClickHandler(history.event_id,playerData)}
                >
                  আবার খেলুন →
                </button>
              </div>
              <div className={styles.progressSection}>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progressFill}
                    style={{ width: `${scorePercentage}%` }}
                  />
                </div>
                <span className={styles.percentageText}>{convertToBengaliNumber(Math.round(scorePercentage))}% স্কোর</span>
              </div>
            </div>
          )}else{
            return (<>
            <div key={index} className={styles.historyItemContainer} style={{ backgroundColor: index % 2 === 0 ? '#D8EDFF' : '#D1FFED' }}>
              <div className={styles.historyItem}>
                <div className={styles.leftSection}>
                  <div className={styles.quizInfo}>
                    <h4>
                      {matchingEvent.events}
                    </h4>
                    <p>স্কোর: <span className={styles.scoreText}>{convertToBengaliNumber(Math.round(history.score))}</span></p>
                  </div>
                </div>
                <button
                  className={styles.playAgainBtn}
                  onClick={() => quizClickHandler(history.event_id,playerData)}
                >
                  আবার খেলুন →
                </button>
              </div>
            </div>
            </>)
          }
        })}
      </div>
    </div>
  )
}

export default PlayingHistory