import React, { useContext, useEffect } from 'react';
import styles from './ResultPage.module.css';
import gsap from 'gsap';
import { pushCleverTapEvent } from '../../helper/clevertapFunction';
import { dataContext } from '../../App';

const ResultPage = ({setActiveBottom, setActiveBoard,quizClickHandler}) => {
  const {playerData} = useContext(dataContext);

  const rightAnswer = parseInt(sessionStorage.getItem('quizPlay_totalRightScore')) || 0;
  const wrongAnswer = parseInt(sessionStorage.getItem('quizPlay_totalWrongScore')) || 0;
  const passingScore = parseInt(sessionStorage.getItem('quizPlay_passingScore')) || 1;
  const eventId = sessionStorage.getItem('quizPlay_eventId');
  let isPassed = (rightAnswer >= passingScore);
  useEffect(() => {
    if(sessionStorage.getItem('played_eventId') == 67){pushCleverTapEvent('CompletePlay-EnglishWordly')}
    else if(sessionStorage.getItem('played_eventId') == 79){pushCleverTapEvent('CompletePlay-BanglaWordly')}
    sessionStorage.removeItem('played_eventId');
  }, [])
  useEffect(() => {
    if (isPassed) {
      gsap.timeline({ delay: 1 })
        .fromTo(`.${styles.fireworks}`, {
        y: '-100%',
        scale: .6,
        duration: 2,
        ease: 'power1.inOut',
        display: 'none'
      }, {
        y: '0%',
        scale: 1.1,
        duration: 2,
        ease: 'power1.inOut',
        display: 'block'
      })
      .to(`.${styles.fireworks}`, {
        opacity: 0,
        duration: .5,
        ease: 'power1.inOut',
        display: 'none'
      });
    }
    const headerStyle = isPassed ? {
      background: 'radial-gradient(112.5% 111.22% at 104.39% 0%, #a788fd 0%, #250878 100%)'
    } : {
      background: 'linear-gradient(161.15deg, #757575 12.73%, #050505 72.95%)'
    };

    gsap.set(`.${styles.header}`, headerStyle);
  }, [isPassed]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={require('./fireworks.png')} className={styles.fireworks} alt="result-page-header" />
        <img src={require('./trophy.png')} className={styles.trophy} alt="result-page-header" />
        <div>
          <div className={styles.congratsTitle}>অভিনন্দন</div>
          <div className={styles.congratsText}>আপনি পাজল গেমটি সম্পূর্ণভাবে শেষ করেছেন।</div>
        </div>
        <div className={styles.score}>আপনার স্কোর: {rightAnswer}</div>
      </div>
      <div className={styles.stats}>
        <div>
          <p style={{ fontSize: 14, fontWeight: 400 }}>সঠিক উত্তর</p>
          <p style={{ fontSize: 16, fontWeight: 500 }}>{rightAnswer}</p>
        </div>
        <div>
          <p style={{ fontSize: 14, fontWeight: 400 }}>ভুল উত্তর</p>
          <p style={{ fontSize: 16, fontWeight: 500 }}>{wrongAnswer}</p>
        </div>
      </div>
      <button className={styles.button} onClick={() => {
        quizClickHandler(eventId,playerData);
        setActiveBottom('home');
      }}>আবার খেলুন</button>
      <button className={styles.buttonOutline} onClick={() => {
        console.log('leaderboardClicked',eventId)
        setActiveBoard(parseInt(eventId));
        setActiveBottom('leader');
      }}>লিডারবোর্ড দেখুন</button>
    </div>
  );
}

export default ResultPage;