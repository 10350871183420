import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './RewardsModal.module.css'
import Accordion from 'react-bootstrap/Accordion';
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';
import styles from './RewardsModal.module.css'
import './RewardsModal.css'

const RewardsModal = ({ showRewardsModal, setShowRewardsModal }) => {
  const { playerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowRewardsModal(false)
  }

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("rewards", msisdn);
    analyticEvent('rewards', 'rewards-view')
    pushCleverTapEvent('CheckIn-Prize');
  }, [])

  return (
    <>
      <div className={styles.rewardsModal}>
        <div className={styles.rewardsModalHeader}>
          <img src={require('./rewards.png')} className={styles.rewardsModalIcon} alt="medal" />
          <span className={styles.rewardsModalTitle}>প্রাইজের তালিকা</span>
        </div>
        <Accordion className={styles.accordion}>
              <Accordion.Item eventKey="0" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionButton}>দৈনিক পুরস্কার</Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                প্রতিদিনের অংশগ্রহণকারীদের মধ্যে সেরা ৩০ জন সঠিক উত্তর প্রদানকারী কে ১০ টাকা বিকাশ ক্যাশ ইন করা হবে।
                  <br />
                  <br />
                  **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionButton}>সাপ্তাহিক পুরস্কার</Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  প্রতি সপ্তাহের ৩ জন সর্বোচ্চ স্কোর ধারী অংশগ্রহণকারী কে ৩০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে।
                  <ul>
                    <li>প্রথম পুরস্কার: ১৫০ টাকা</li>
                    <li>দ্বিতীয় পুরস্কার: ১০০ টাকা</li>
                    <li>তৃতীয় পুরস্কার: ৫০ টাকা</li>
                  </ul>
                  **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionButton}>১০ টাকা ক্যাশ ইন প্রাইজ</Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                  আপনার রেফার কোড ব্যাবহার করে কেউ যদি সাবস্ক্রিপশন করে, তাহলে প্রতি সফল সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকা ক্যাশ ইন। রেফারি এবং রেফারাল ইউজার উভয়ই ১০ টাকা ক্যাশ ইন পাবেন।
                  <br />
                  <br />
                  **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      </div>
    </>
  )
}

export default RewardsModal