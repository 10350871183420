import React, { useContext } from 'react'
import './Sidebar.css'
import { motion } from "framer-motion"
import axios from "axios"
import { MdArrowForward } from "react-icons/md"
import { FaArrowLeft } from "react-icons/fa6"

// Import contexts and helpers
import { baseUrl, dataContext } from '../../App'
import handleImageError from '../../helper/defaultImage'

// Import assets
import sidebarClose from '../../assets/img/v2/x.png'
import {
  home, homeSelected,
  profile, profileSelected,
  rewards, rewardsSelected,
  referral, referralSelected,
  subscription, subscriptionSelected,
  help, helpSelected,
  tc, tcSelected
} from './assets'

const menuItems = [
  { id: 'home', label: 'হোম', icon: home, selectedIcon: homeSelected, requiresAuth: false },
  { id: 'profile', label: 'প্রোফাইল', icon: profile, selectedIcon: profileSelected, requiresAuth: true },
  { id: 'rewards', label: 'প্রাইজ', icon: rewards, selectedIcon: rewardsSelected, requiresAuth: false },
  { id: 'referral', label: 'রেফার', icon: referral, selectedIcon: referralSelected, requiresAuth: false },
  { id: 'subscription', label: 'সাবস্ক্রিপশন', icon: subscription, selectedIcon: subscriptionSelected, requiresAuth: false },
  { id: 'help', label: 'হেল্প', icon: help, selectedIcon: helpSelected, requiresAuth: false },
  { id: 'tc', label: 'শর্তাবলী', icon: tc, selectedIcon: tcSelected, requiresAuth: false },
]

const MenuItem = ({ item, isActive, onClick }) => (
  <div 
    className="singleMenu" 
    onClick={onClick}
    style={isActive ? { color: '#5B1674' } : {}}
  >
    <img 
      src={isActive ? item.selectedIcon : item.icon} 
      style={{ width: '20px', height: '20px' }} 
      alt="" 
    />
    &nbsp; {item.label}
  </div>
)

const Sidebar = ({ props }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext)
  const { 
    showSidebar, 
    setShowSidebar, 
    showLoginModal, 
    setShowLoginModal, 
    activeBottom, 
    setActiveBottom 
  } = props

  const handleLogout = async () => {
    const lastToken = token
    
    // Clear user data
    setToken(null)
    setPlayerData({})
    localStorage.setItem('AuthToken', '')
    localStorage.setItem("user_msisdn", "")
    
    // Update UI state
    setShowSidebar(false)
    setActiveBottom("home")

    // Make logout API call
    try {
      await axios.post(
        `${baseUrl}/api/logout/`,
        { empty: '' },
        { headers: { Authorization: `Token ${lastToken}` } }
      )
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  const handleMenuClick = (menuItem) => {
    if (menuItem.requiresAuth && !playerData?.id) {
      setShowLoginModal(true)
    } else {
      setActiveBottom(menuItem.id)
    }
    setShowSidebar(false)
  }

  const getAvatarSrc = () => {
    if (playerData?.avatar_img) {
      return `${baseUrl}${playerData.avatar_img}`
    }
    return require(`../../assets/avatar/avatar${playerData?.avatar_id || 1}.png`)
  }

  return (
    <>
      <motion.div 
        className="ppm_side_bar"
        animate={showSidebar ? { x: '100%', opacity: 1 } : { x: '0%', opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <img 
          src={sidebarClose} 
          onClick={() => setShowSidebar(false)} 
          className="sidebarClose" 
          alt="Close sidebar" 
        />

        <div className="headerPart">
          {playerData?.id ? (
            <>
              <img 
                src={getAvatarSrc()} 
                onError={handleImageError} 
                className="sidebarAvatar" 
                alt="User avatar" 
              />
              <div className="detailBox">
                <div className="detailName">
                  {playerData?.name || `Player-${playerData?.id || ''}`}
                </div>
                <div className="detailMobile">
                  {playerData?.id ? `0${(playerData.msisdn).toString().slice(-10)}` : "-"}
                </div>
              </div>
            </>
          ) : (
            <div 
              className="loginBtn" 
              onClick={() => { 
                setShowLoginModal(true)
                setShowSidebar(false) 
              }}
            >
              লগইন করুন <MdArrowForward />
            </div>
          )}
        </div>

        <div className="menuPart">
          {menuItems.map(item => (
            <MenuItem
              key={item.id}
              item={item}
              isActive={activeBottom === item.id}
              onClick={() => handleMenuClick(item)}
            />
          ))}
        </div>

        {playerData?.id && (
          <div className="sideBarLogout">
            <div className="logoutBtn" onClick={handleLogout}>
              <FaArrowLeft /> &nbsp; লগ আউট
            </div>
          </div>
        )}
      </motion.div>

      {showSidebar && (
        <div 
          className="ppm_close_bar" 
          onClick={() => setShowSidebar(false)}
        />
      )}
    </>
  )
}

export default Sidebar