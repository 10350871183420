import React, { useContext, useState } from 'react'
import './BlockRound.css'
import '../../../assets/css/modal.css'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaMedal  } from "react-icons/fa";

const BlockRound = ({showBlockModal, setShowBlockModal,playedRoundNumber}) => {
  const navigate = useNavigate()
  function hideModalOperation() {
    setShowBlockModal(false)
    navigate(`../`, { replace: true })
  }
  let banglaNumWord = {1:'এক',2:'দুই',3:'তিন',4:'চার',5:'পাঁচ',6:'ছয়',7:'সাত',8:'আট',9:'নয়',10:'দশ'};
  let roundLimit = banglaNumWord[playedRoundNumber];
  return (
    <>
      <Modal show={showBlockModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>রাউন্ড শেষ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{textAlign: 'center',width: '80%', fontSize:'16px'}}>{`আপনি আজ ইতোমধ্যে দুই বার খেলেছেন। কাল
            আবার খেলুন।`}</div>
          <div className="allBtn d-flex justify-content-center w-90 gap-4 mt-3">
            <div className="btn btn-light modalButtonIcon" onClick={()=>{navigate(`../?page=home`, { replace: true });}}><FaHome /></div>
            <div className="btn btn-light modalButtonIcon" onClick={()=>{navigate(`../?page=leader`, { replace: true });}}><FaMedal /></div>
          </div></Modal.Body>

      </Modal>
    </>
  )
}

export default BlockRound