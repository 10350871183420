import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import campLadingImag from './bg_land.png'
import logo from './wordly.png'
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign2.module.css";
import LoginModalCampaign2 from "../../components/LoginModalCampaign2/LoginModalCampaign2";

const Campaign2 = ({ campaignNumber }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("qcid");
  const { playerData } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);

  useEffect(() => {
    // Redirect if no campaign ID
    if (!campaignId) {
      navigate("../");
      return;
    }
    sessionStorage.setItem('cid', campaignId);

    // Handle login modal
    const timer = setTimeout(() => {
      setShowLoginModalCampaign(true);
    }, 2500);

    // activity logs
    const msisdn = playerData?.msisdn ?? null;
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission(`campaign${campaignNumber}`, msisdn);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className={styles.landingPage}>
      <img src={logo} alt="" style={{width: "26%", position: "absolute", top: "50%", left: "37%", transform: "translateY(-255%)"}} />
      <LoginModalCampaign2
        showLoginModalCampaign={showLoginModalCampaign}
        setShowLoginModalCampaign={setShowLoginModalCampaign}
        activeBottom={activeBottom}
        setActiveBottom={setActiveBottom}
      />
      <div className={styles.content} style={{ position: "absolute", bottom: "2%", padding: '24px', fontSize: "14px", color: "#232323", fontWeight: "500", textAlign: 'justify' }}>
        <p>
          Wordly is a subscription-based service. Price for user 30BDT/week. Users can unsubscribe from the service any time to click on unsubscribe button.
          <div style={{ textDecoration: "underline", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px' }}
            onClick={() => { navigate(`../?page=tc&qcid=${campaignId}`); }}>Terms & Conditions</div>
        </p>
      </div>
    </div>
  );
};

export default Campaign2;
