import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { routes } from "./routes";
import { activityLogSubmission, setupSecurityEventListeners } from "./helper";
import { fetchEvents } from "./services";

export const baseUrl = `https://cms.quizard.live`;
export const portal = 18;
export const dataContext = createContext();



function App() {
  const [token, setToken] = useState(localStorage.getItem("AuthToken") || "");
  const [playerData, setPlayerData] = useState({});
  const [events, setEvents] = useState([]);
  const [extraData, setExtraData] = useState([]);

  useEffect(() => {
    // Handle refer code
    const url = new URL(window.location.href);
    let referCode = url.searchParams.get("refer_code");
    if(referCode){sessionStorage.setItem('refer_code',referCode)}
    let promoCode = url.searchParams.get("promo_code");
    if(promoCode){sessionStorage.setItem('promo_code',promoCode)}

    // Get events - (rules table in DB)
    const getEvents = async () => {
      const eventsData = await fetchEvents();
      setEvents(eventsData);
    };
    getEvents();

    // Setup security
    setupSecurityEventListeners();

    // Log activity
    activityLogSubmission("landing", localStorage.getItem('user_msisdn') || null);
  }, []);
  // Initialize GA
  ReactGA.initialize([{ trackingId: "G-Q3987EFP0Z" }]);

  return (
    <div className="App">
      <div className="ppm_quiz_body">
        <dataContext.Provider
          value={{
            token,
            setToken,
            playerData,
            setPlayerData,
            events,
            setEvents,
            portal,
            extraData, setExtraData
          }}
        >
          <RouterProvider router={createBrowserRouter(routes)} />
        </dataContext.Provider>
      </div>
    </div>
  );
}

export default App;
