import axios from 'axios';
import { baseUrl, portal } from '../App';

/**
 * Fetches event rules from the API for a specific portal
 * @async
 * @function fetchEvents
 * @returns {Promise<Array>} Array of event rules. Returns empty array if request fails
 * @throws {Error} Logs error to console if request fails
 */
export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/rules/?portal=${portal}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    return [];
  }
};