import axios from "axios";
import { baseUrl, portal } from "../App";

export default async function activityLogSubmission(pageName, msisdn = null) {
  // Gather user and session data
  const {
    navigator: { userAgent },
    location: { href: sourceUrl, search }
  } = window;
  
  const urlParams = new URLSearchParams(search);
  let storedMsisdn = localStorage.getItem('user_msisdn')
  let userMsisdn = msisdn || (storedMsisdn ? parseInt(storedMsisdn) : null)
  const cid = urlParams.get('qcid') || sessionStorage.getItem('cid');

  // Prepare payload
  const payload = {
    msisdn: userMsisdn,
    page_name: pageName,
    source_url: sourceUrl,
    useragent: userAgent,
    cid,
    company: portal
  };

  try {
    await axios.post(`${baseUrl}/api/v1/activity_log/`, payload);
  } catch (error) {
    console.error('Activity log submission failed:', error);
  }
}
