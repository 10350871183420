import styles from './SpellingGamePlay.module.css'
import CheckCircle from './CheckCircle.png'
import CrossCircle from './CrossCircle.png'
const InfoContainer = ({bgColor, circleType, headerText, subText}) => {
    
    return (
        <div className={styles.infoContainer} style={{ backgroundColor: bgColor }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <img src={circleType === 'check' ? CheckCircle : CrossCircle} className={styles.checkCircle} style={{ width: '24px', height: '24px' }} alt="" />
                <div style={{ fontSize: '24px', fontWeight: '700' }}>{headerText}</div>
            </div>
            <div>
                <div style={{ fontSize: '16px', fontWeight: '500' }}>{subText}</div>
            </div>
        </div>
    )
}

export default InfoContainer