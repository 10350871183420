import React, { useContext, useEffect, useState } from "react";
import "./Leaderboard.css";
import crown from "../../assets/img/v4/crown.png";
import { baseUrl, dataContext, portal } from "../../App";
import axios from "axios";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Observer } from "gsap/Observer";
import { useSwipeable } from "react-swipeable";
// import { pushCleverTapEvent } from "../../helper/clevertapFunction";
import styles from './Leaderboard.module.css';
import CurrentUserCard from './CurrentUserCard';
// const baseUrl = `https://quizcms.purplepatch.online`;

const Leaderboard = ({ activeBoard, setActiveBoard, toggleToUpdate, setToggleToUpdate }) => {
  const { playerData, events } = useContext(dataContext);
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [rankOneData, setRankOneData] = useState({});
  const [rankTwoData, setRankTwoData] = useState({});
  const [rankThreeData, setRankThreeData] = useState({});
  const [currentUserData, setCurrentUserData] = useState({});

  const [activeTab, setActiveTab] = useState('all'); // 'all' or 'winners'
  const [activeWeeklyTab, setActiveWeeklyTab] = useState('daily'); // 'daily' or 'weekly' or 'monthly'

  const handleEventChange = (eventId) => {
    setActiveBoard(eventId);
    analyticEvent('leaderboard', `switch-event-${eventId}`);
    // pushCleverTapEvent('Switch-Leaderboard-Event', { eventId });
  };
  const handleImageError = (event) => {
    event.target.src = "/avatar1.png";
  };
  useEffect(() => {
    setToggleToUpdate(!toggleToUpdate)
  }, [])

  useEffect(() => {
    if (activeTab === 'all') {
      let userLeaderboard = playerData?.leaderboard ?? [];
      if (events.length) {
        if (!activeBoard) setActiveBoard(events[0]?.id);
        let activeUserLeaderboard = activeBoard ?? events[0]?.id;
        const currentUserBoardData = userLeaderboard?.find(item => item.event_id === activeUserLeaderboard) || {};
        setCurrentUserData(currentUserBoardData);
        axios
          .get(`${baseUrl}/api/leaderboard/?portal=${portal}&event_id=${activeBoard ? activeBoard : events[0]?.id}`)
          .then((response) => {
            let totalData = response.data;
            if (totalData.length > 0) { setRankOneData(totalData[0]); } else { setRankOneData({}); }
            if (totalData.length > 1) { setRankTwoData(totalData[1]); } else { setRankTwoData({}); }
            if (totalData.length > 2) { setRankThreeData(totalData[2]); } else { setRankThreeData({}); }
            if (totalData.length > 3) { setLeaderBoardData(totalData.slice(3)); } else { setLeaderBoardData([]); }
          });
      }
    } else if (activeTab === 'winners') {
      // TODO: activeWeeklyTab update also need to update the winner list of profile.
      let userLeaderboard = playerData?.winner ?? [];
      const currentUserBoardData = userLeaderboard[0] || {};
      setCurrentUserData({});
      if (activeWeeklyTab === 'daily') {
        axios
          .get(`${baseUrl}/api/wordlyDailyWinner/`)
          .then((response) => {
            let totalData = response.data.map(item => ({
              User_Rank: item.rank,
              msisdn: item.msisdn,
              score: parseInt(item.total_right_count),
              time_taken: parseInt(item.total_time_taken),
              name: item.name,
              username: item.msisdn,
              avatar_id: item.avatar_id,
              avatar_img: item.avatar_img
            }));

            if (totalData.length > 0) { setRankOneData(totalData[0]); } else { setRankOneData({}); }
            if (totalData.length > 1) { setRankTwoData(totalData[1]); } else { setRankTwoData({}); }
            if (totalData.length > 2) { setRankThreeData(totalData[2]); } else { setRankThreeData({}); }
            if (totalData.length > 3) { setLeaderBoardData(totalData.slice(3)); } else { setLeaderBoardData([]); }
          });
      } else if (activeWeeklyTab === 'weekly') {
        axios
          .get(`${baseUrl}/api/wordlyWeeklyWinner/`)
          .then((response) => {
            let totalData = response.data.map(item => ({
              User_Rank: item.rank,
              msisdn: item.msisdn,
              score: parseInt(item.total_right_count),
              time_taken: parseInt(item.total_time_taken),
              name: item.name,
              username: item.msisdn,
              avatar_id: item.avatar_id,
              avatar_img: item.avatar_img
            }));
            if (totalData.length > 0) { setRankOneData(totalData[0]); } else { setRankOneData({}); }
            if (totalData.length > 1) { setRankTwoData(totalData[1]); } else { setRankTwoData({}); }
            if (totalData.length > 2) { setRankThreeData(totalData[2]); } else { setRankThreeData({}); }
            setLeaderBoardData([])
          });
      }
    }
  }, [activeBoard, activeTab, playerData, activeWeeklyTab]);

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("leaderboard", msisdn);
    analyticEvent('leaderboard', 'leaderboard-view')
    // pushCleverTapEvent('View-Leaderboard');
  }, [])

  return (
    <div className={styles.container}>
      {/* Top Section - Navigation and Top 3 */}
      <div className={styles.topSection}>
        {/* Main Navigation */}
        <div className={styles.mainNav}>
          <button
            className={`${styles.mainNavButton} ${activeTab === 'all' ? styles.mainNavActive : ''}`}
            onClick={() => setActiveTab('all')}
          >
            ডেইলি স্কোর
          </button>
          <button
            className={`${styles.mainNavButton} ${activeTab === 'winners' ? styles.mainNavActive : ''}`}
            onClick={() => { setActiveTab('winners'); activityLogSubmission("winner-list"); }}
          >
            উইনার লিস্ট
          </button>
        </div>

        {/* Sub Navigation */}
        {activeTab === 'all' && (
          <div className={styles.subNav}>
            {events.map((event) => (
              <button
                key={event.id}
                className={`${styles.subNavButton} ${activeBoard === event.id ? styles.subNavActive : ''}`}
                onClick={() => handleEventChange(event.id)}
              >
                {event.events}
              </button>
            ))}
          </div>
        )}
        {activeTab === 'winners' && (          
          <div className={styles.subNav}>
            <button
              className={`${styles.subNavButton} ${activeWeeklyTab === "daily" ? styles.subNavActive : ''}`}
              onClick={() => setActiveWeeklyTab("daily")}
            >
              {`ডেইলি উইনার লিস্ট`}
            </button>
            <button
              className={`${styles.subNavButton} ${activeWeeklyTab === "weekly" ? styles.subNavActive : ''}`}
              onClick={() => setActiveWeeklyTab("weekly")}
            >
              {`উইকলি উইনার লিস্ট`}
            </button>
          </div>
        )}

        <div className={styles.topPlayers}>
          {/* Second Place */}
          <div className={styles.playerCard2}>
            <div className={styles.avatarWrapper}>
              <img
                src={
                  rankTwoData?.avatar_img
                    ? `${baseUrl}/media/${rankTwoData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankTwoData?.avatar_id || 1}.png`)
                }
                alt="Second"
                className={styles.avatar2}
                onError={handleImageError}
              />
              <div className={styles.crownNumber2}></div>
            </div>
            <div className={styles.dataWrapper}>
              <div className={styles.msisdn}>
                {rankTwoData?.msisdn ? `0${(rankTwoData.msisdn).toString().slice(0, 3)}****${(rankTwoData.msisdn).toString().slice(-3)}` : ''}
              </div>
              {rankTwoData?.msisdn && <div className={styles.scoreInfo}>
                <span className={styles.scoreValue}>Score: {rankTwoData?.score}</span>
                <span className={styles.timeValue}>{rankTwoData?.time_taken}s</span>
              </div>}
            </div>
          </div>

          {/* First Place */}
          <div className={styles.playerCard1}>
            <div className={styles.avatarWrapper}>
              <img src="/crown.png" alt="Crown" className={styles.crown} />
              <img
                src={
                  rankOneData?.avatar_img
                    ? `${baseUrl}/media/${rankOneData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankOneData?.avatar_id || 1}.png`)
                }
                alt="First"
                className={styles.avatar1}
                onError={handleImageError}
              />
              <div className={styles.crownNumber}></div>
            </div>
            <div className={styles.dataWrapper} style={{ bottom: '24px' }}>
              <div className={styles.msisdn} style={{ fontSize: '14px', fontWeight: '500' }}>
                {rankOneData?.msisdn ? `0${(rankOneData.msisdn).toString().slice(0, 3)}****${(rankOneData.msisdn).toString().slice(-3)}` : ''}
              </div>
              {rankOneData?.msisdn && <div className={styles.scoreInfo}>
                <span className={styles.scoreValue} style={{ fontSize: '16px', fontWeight: '700' }}>Score: {rankOneData?.score}</span>
                <span className={styles.timeValue} style={{ fontSize: '12px', fontWeight: '500' }}>{rankOneData?.time_taken}s</span>
              </div>}
            </div>
          </div>

          {/* Third Place */}
          <div className={styles.playerCard3}>
            <div className={styles.avatarWrapper}>
              <img
                src={
                  rankThreeData?.avatar_img
                    ? `${baseUrl}/media/${rankThreeData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankThreeData?.avatar_id || 1}.png`)
                }
                alt="Third"
                className={styles.avatar3}
                onError={handleImageError}
              />
              <div className={styles.crownNumber3}></div>
            </div>
            <div className={styles.dataWrapper}>
              <div className={styles.msisdn}>
                {rankThreeData?.msisdn ? `0${(rankThreeData.msisdn).toString().slice(0, 3)}****${(rankThreeData.msisdn).toString().slice(-3)}` : ''}
              </div>
              {rankThreeData?.msisdn && <div className={styles.scoreInfo}>
                <span className={styles.scoreValue}>Score: {rankThreeData?.score}</span>
                <span className={styles.timeValue}>{rankThreeData?.time_taken}s</span>
              </div>}
            </div>
          </div>
        </div>
      </div>

      {/* Replace the current user section with the new component */}
      <CurrentUserCard
        currentUserData={currentUserData}
        playerData={playerData}
        baseUrl={baseUrl}
      />

      {/* Bottom Section - Other Users */}
      <div className={styles.otherUsersSection}>
        {leaderBoardData.map((player, index) => (
          <div key={index} className={styles.userRow}>
            <div className={styles.rank}>{player.User_Rank}</div>
            <img
              src={
                player.avatar_img
                  ? `${baseUrl}/media/${player.avatar_img}`
                  : require(`../../assets/avatar/avatar${player.avatar_id || 1}.png`)
              }
              alt="Avatar"
              className={styles.userAvatar}
              onError={handleImageError}
            />
            <div className={styles.userInfo}>
              <div className={styles.userMsisdn}>
                {player.msisdn ? `0${(player.msisdn).toString().slice(0, 3)}****${(player.msisdn).toString().slice(-3)}` : ''}
              </div>
              <div className={styles.timeAndScore}>
                <span>{player.time_taken}s</span>
                <span>{player.score}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
