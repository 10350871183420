import React, { useContext } from 'react'
import styles from './Bottombar.module.css'
import { dataContext } from '../../App';

// Import all images
import homeImg from './assets/home.png'
import selectedHomeImg from './assets/home.png'
import leaderImg from './assets/leaderboard.png'
import selectedLeaderImg from './assets/leaderboard.png'
import profileImg from './assets/profile.png'
import selectedProfileImg from './assets/profile.png'
import winnerImg from './assets/winner.png'
import selectedWinnerImg from './assets/winner.png'
import quizardImg from './assets/quizard_icon.png'
import khelunImg from './assets/khelun.svg'
import { activityLogSubmission } from '../../helper';

// Navigation item configuration
const NAV_ITEMS = [
  {
    id: 'home',
    label: 'হোম',
    icon: homeImg,
    selectedIcon: selectedHomeImg,
  },
  {
    id: 'quizard',
    label: 'কুইজার্ড',
    icon: quizardImg,
    isSpecialLink: true,
  },
  {
    id: 'leader',
    label: 'লিডারবোর্ড',
    icon: leaderImg,
    selectedIcon: selectedLeaderImg,
  },
  {
    id: 'profile',
    label: 'প্রোফাইল',
    icon: profileImg,
    selectedIcon: selectedProfileImg,
    requiresAuth: true,
  },
];

const NavItem = ({ item, isActive, onClick, setShowShobDekho }) => {
  const icon = isActive && item.selectedIcon ? item.selectedIcon : item.icon;

  if (item.isSpecial) {
    return (
      <div className={styles.khelunImg} onClick={() => setShowShobDekho(true)}>
        <img src={icon} alt={item.label} />
      </div>
    );
  }
  if (item.isSpecialLink) {
    return (
      <div
      className={`${styles.nav_item} ${isActive ? styles.active : ''}`}
      onClick={()=>{window.open('https://www.quizard.live/?qcid=115','_blank');activityLogSubmission('quizard_from_wordly_navbar');}}
    >
      <img src={icon} alt={item.label} />
    </div>
    );
  }

  return (
    <div
      className={`${styles.nav_item} ${isActive ? styles.active : ''}`}
      onClick={onClick}
    >
      <img src={icon} alt={item.label} />
    </div>
  );
};

const Bottombar = ({ activeBottom, setActiveBottom, setShowLoginModal, setShowShobDekho }) => {
  const { playerData } = useContext(dataContext);

  const handleNavClick = (item) => {
    if (item.requiresAuth && !playerData?.id) {
      setShowLoginModal(true);
    } else {
      setActiveBottom(item.id);
  }
};

return (
  <div className={styles.ppm_bottom_bar}>
    <div className={styles.bottom_nav}>
      {NAV_ITEMS.map(item => (
        <NavItem
          key={item.id}
          item={item}
          setShowShobDekho={setShowShobDekho}
          isActive={activeBottom === item.id}
          onClick={() => handleNavClick(item)}
        />
      ))}
    </div>
  </div>
);
};

export default Bottombar;