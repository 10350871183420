// import clevertap from 'clevertap-web-sdk';
function handleCleverTapEventPushClick(eventName, description) {
  console.log("handleCleverTapEventPushClick", eventName, description);
  return;
  // clevertap.event.push(eventName, {
  //   ...description,
  //   "Date": new Date()
  // }); 
}
function pushCleverTapEvent(eventName) {
  console.log("pushCleverTapEvent", eventName);
  // clevertap.event.push(eventName);
}

export { handleCleverTapEventPushClick, pushCleverTapEvent }