import styles from './SpellingGamePlay.module.css'
import './SpellingGamePlay.css'
import { IoArrowUndoOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { TfiReload } from "react-icons/tfi";
import { checkingWords } from './wordBank';
import InfoContainer from './InfoContainer';
import { getTimeInBangla, secondsToMS } from './banglaTime';

const SpellingGamePlay = ({props}) => {
  const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const [allLetters, setAllLetters] = useState([])
  const [selectedLetters, setSelectedLetters] = useState('')
  const [alreadyFoundWord, setAlreadyFoundWord] = useState([])
  const [showInfoContainerError, setShowInfoContainerError] = useState(false)
  const [showInfoContainerSuccess, setShowInfoContainerSuccess] = useState(false)
  const [showInfoContainerAlreadyFound, setShowInfoContainerAlreadyFound] = useState(false)
  const [showInfoContainerMin3Letters, setShowInfoContainerMin3Letters] = useState(false)
  const { insertData, playerData, playerRound, eventDetails, setFinalScore, setShowResultModal, gameDone, allQuestions, remainingTime, timerWidth, setShowPopUp,rightAudio,wrongAudio } = props;
  function hiveClicked(letter) {
    setSelectedLetters(selectedLetters + letter)
  }
  function handleReload() {
    console.log('reload')
    setSelectedLetters('');
    const letters = [...allLetters];
    for (let i = letters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [letters[i], letters[j]] = [letters[j], letters[i]];
    }
    setAllLetters(letters);
  }
  function handleSubmit() {
    
    if(selectedLetters.length < 3) {
      setShowInfoContainerMin3Letters(true);
      setTimeout(() => {setShowInfoContainerMin3Letters(false);}, 1000);
      return;
    }
    if(alreadyFoundWord.includes(selectedLetters)) {
      setShowInfoContainerAlreadyFound(true);
      setTimeout(() => {setShowInfoContainerAlreadyFound(false);}, 1000);
      return;
    }
    if(checkingWords.includes(selectedLetters.toLowerCase())) {
      new Audio(rightAudio).play()
      setShowInfoContainerSuccess(true);
      setTimeout(() => {setShowInfoContainerSuccess(false);}, 1000);
      setTotalRightAnswer(totalRightAnswer + 1)
      sessionStorage.setItem('quizPlay_totalWrongScore', Number(sessionStorage.getItem('quizPlay_totalRightScore')) + 1);
      insertData(1, playerData.msisdn, playerRound + 1, allQuestions[0].id, selectedLetters, allQuestions[0]?.category, eventDetails.id)
      setSelectedLetters('');
      setAlreadyFoundWord([...alreadyFoundWord, selectedLetters])
    } else {
      new Audio(wrongAudio).play()
      setShowInfoContainerError(true);
      setTimeout(() => {setShowInfoContainerError(false);}, 1000);
    }
  }
  useEffect(()=>{
    insertData(0, playerData.msisdn, playerRound + 1, allQuestions[0].id, 'Initial', allQuestions[0]?.category, eventDetails.id)
    sessionStorage.setItem('quizPlay_totalRightScore', 0);
    sessionStorage.setItem('quizPlay_totalWrongScore', 0);
  },[])
  useEffect(() => {
    console.log(allQuestions[0]?.question)
    let foundWord = allQuestions[0]?.question;
    const letters = foundWord.toUpperCase().split('');
    for (let i = letters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [letters[i], letters[j]] = [letters[j], letters[i]];
    }
    console.log(letters)
    setAllLetters(letters);
  }, [allQuestions])
  

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '6%' }}><img src={require('./logo.png')} alt="" style={{ width: '35%', margin: '0 auto' }} />  </div>
      <div style={{ width: '100%', padding: '16px' }}>
      <div style={{ width: '100%', padding: '16px',display:'flex',justifyContent:'center',alignItems:'center',gap:'6px' }}>
        <div className={styles.timerContainer}>
          <div className={styles.timerBar} style={{ width: `${timerWidth}%` }}></div>
        </div>
        <img src={require('./box.png')} alt="" style={{ width: '24px',height:'24px' }} onClick={() => setShowPopUp(true)} />
      </div>

        
      </div>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative',margin:'16px', border:'2px solid #fff',borderRadius:'6px',gap:'16px',justifyContent:'space-between' }}>
          <div style={{display: 'flex', alignItems: 'center', position: 'relative',margin:'16px',borderRadius:'6px',gap:'16px'}}>
          <div className="correctHead">স্কোর:</div>
          <div className="correctN" style={{ fontSize: '18px', color: '#fff' }}>{totalRightAnswer}</div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', position: 'relative',margin:'16px',borderRadius:'6px',width:'100px',color:'#fff',justifyContent:'space-between',fontFamily:'ContinuumBold',fontWeight:'600'}}>
            <div>সময়:</div>
            <div className="">{getTimeInBangla(secondsToMS(remainingTime))}</div>
          </div>
      </div>


      <div className="playContainer" style={{ position: 'relative' }}>
        <div>
          <span className="toolTipText">Min 3 Letters</span>
          <input 
            type="text" 
            className="inputField" 
            placeholder="tap / click" 
            value={selectedLetters} 
            onChange={(e) => setSelectedLetters(e.target.value)}
            disabled
          />
        </div>
        <div className="bee-container">
          <div className="bee-hive">
            <div className="hive-cell center cellCenter" id="p1">
              <p className="cell-1" onClick={() => hiveClicked(allLetters[0])}>{allLetters[0]}</p>
            </div>
            <div className="hive-cell upperLeft normalCell" id="p2">
              <p className="cell-2" onClick={() => hiveClicked(allLetters[1])}>{allLetters[1]}</p>
            </div>
            <div className="hive-cell top normalCell" id="p3">
              <p className="cell-3" onClick={() => hiveClicked(allLetters[2])}>{allLetters[2]}</p>
            </div>
            <div className="hive-cell upperRight normalCell" id="p4">
              <p className="cell-4" onClick={() => hiveClicked(allLetters[3])}>{allLetters[3]}</p>
            </div>
            <div className="hive-cell bottomLeft normalCell" id="p5">
              <p className="cell-5" onClick={() => hiveClicked(allLetters[4])}>{allLetters[4]}</p>
            </div>
            <div className="hive-cell bottom normalCell" id="p6">
              <p className="cell-6" onClick={() => hiveClicked(allLetters[5])}>{allLetters[5]}</p>
            </div>
            <div className="hive-cell bottomRight normalCell" id="p7">
              <p className="cell-7" onClick={() => hiveClicked(allLetters[6])}>{allLetters[6]}</p>
            </div>
          </div>
        </div>
        <div className="operationBtns">
          <div className={styles.deleteBtn} onClick={() => setSelectedLetters(prev => prev.slice(0, -1))}>Delete</div>
          <div className={styles.rePosition} onClick={() => handleReload()}><TfiReload /></div>
          <div className={styles.submitBtn} onClick={() => handleSubmit()}>Submit</div>
          {/* <img src="./design/delete.png" className="deleteBtn" alt="" />
          <img src="./design/reload.png" className="reloadBtn" alt="" />
          <img src="./design/submit.png" className="submitBtn" alt="" />
          <img src="./design/play.png" className="playBtn" alt="" /> */}
        </div>
      </div>
      {showInfoContainerSuccess && <InfoContainer bgColor="#1BFF79" circleType="check" headerText="দারুন" subText="আপনার ওয়ার্ডটি সঠিক হয়েছে " />}
      {showInfoContainerError && <InfoContainer bgColor="#FA6868" circleType="cross" headerText="দুঃখিত" subText="আপনার ওয়ার্ডটি সঠিক হয়নি" />}
      {showInfoContainerAlreadyFound && <InfoContainer bgColor="#FFD700" circleType="cross" headerText="দুঃখিত " subText="এই ওয়ার্ডটি আগেই বসানো হয়েছে" />}
      {showInfoContainerMin3Letters && <InfoContainer bgColor="#FFD700" circleType="cross" headerText="দুঃখিত " subText="কমপক্ষে ৩ অক্ষর দিতে হবে" />}
    </>
  )
}

export default SpellingGamePlay