import React, { useContext, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import styles from './ProfileUpper.module.css';
import handleImageError from '../../../helper/defaultImage';
import { baseUrl, dataContext } from '../../../App';
import { convertToBengaliNumber } from '../../BannerPart3/helper';
const ProfileUpper = ({ playerData,setShowProfileEditModal }) => {
  const {extraData, setExtraData} = useContext(dataContext);
  const [totalRank, setTotalRank] = useState(0);
  useEffect(() => {
    setTotalRank(playerData?.winner?.[0]?.User_Rank);
  }, [playerData]);
  return (
    <div className={styles.profileUpper}>
      {/* Profile Header */}
      <div className={styles.profileHeader}>
        <div className={styles.profileInfo}>
          <img 
            src={playerData?.avatar_img
              ? `${baseUrl}/media/${playerData?.avatar_img}`
              : require(`../../../assets/avatar/avatar${playerData?.avatar_id || 1}.png`)} 
            alt="profile" 
            className={styles.profileAvatar}
            onError={handleImageError}
          />
          <div className={styles.profileName}>
            <h2>{(playerData?.name ? playerData?.name : `0${playerData?.msisdn}`)}</h2>
            <span className={`${styles.subscriberBadge} ${(!playerData?.isSubscribe && !playerData?.isSubscribeTournament) ? styles.unsubscribed : ''}`}>
              {playerData?.isSubscribe || playerData?.isSubscribeTournament ? 'সাবস্ক্রাইবড' : 'আনসাবস্ক্রাইবড'}</span>
          </div>
        </div>
        <button className={styles.editButton} onClick={() => setShowProfileEditModal(true)}>
          <FaEdit />
        </button>
      </div>

      {/* Score Section */}
      <div className={styles.scoreSection}>
        <div className={styles.averageScore}>
          <div>অ্যাভারেজ পাজল স্কোর</div>
          <div>{extraData?.AverageQuizScore ? convertToBengaliNumber(Math.round(extraData?.AverageQuizScore)) : '০'}/৬০</div>
        </div>
        <div className={styles.progressBar}>
          <div 
            className={styles.progress} 
            style={{width: `${((extraData?.AverageQuizScore ?? 0)/60) * 100}%`}}
          ></div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className={styles.statsGrid}>
        <div className={styles.statRow}>
          <div className={styles.statItem}>
            <div className={styles.statContent}>
              <img src={require('./assets/complete.png')} alt="quiz" className={styles.statIcon} />
              <div className={styles.statText}>
                <p>কমপ্লিট পাজল</p>
                <h3>{convertToBengaliNumber(extraData?.CompletedQuiz ?? 0)}</h3>
              </div>
            </div>
          </div>
          
          <div className={styles.divider}></div>
          
          <div className={styles.statItem}>
            <div className={styles.statContent}>
              <img src={require('./assets/trophy.png')} alt="trophy" className={styles.statIcon} />
              <div className={styles.statText}>
                <p>উইনিং রেট</p>
                <h3>{extraData?.DailyWinningRate ? convertToBengaliNumber(Math.round(extraData?.DailyWinningRate)) : '০'}%</h3>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.horizontalDivider}></div>

        <div className={styles.statRow}>
          <div className={styles.statItem}>
            <div className={styles.statContent}>
              <img src={require('./assets/ranking.png')} alt="rank" className={styles.statIcon} />
              <div className={styles.statText}>
                <p>র‍্যাঙ্ক</p>
                <h3>{totalRank ? convertToBengaliNumber(totalRank) : '-'}</h3>
              </div>
            </div>
          </div>
          
          <div className={styles.divider}></div>
          
          <div className={styles.statItem}>
            <div className={styles.statContent}>
              <img src={require('./assets/earning.png')} alt="earning" className={styles.statIcon} />
              <div className={styles.statText}>
                <p>টোটাল আয়</p>
                <h3>৳{convertToBengaliNumber(extraData?.TotalWinAmount ?? 0)}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpper;
