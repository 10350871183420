import React, { useEffect, useRef, useState } from 'react';
import styles from './ReferralLinkBox.module.css';
import { FaCopy } from 'react-icons/fa';

const ReferralLinkBox = ({ referralLink }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [copied, setCopied] = useState(false);

    const timeoutRef = useRef(null); //
    const handleClick = () => {
        navigator.clipboard.writeText(referralLink);
        // Clear any previous timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Show the tooltip
        setShowTooltip(true);

        // Set a new timeout to hide the tooltip after 1000ms
        timeoutRef.current = setTimeout(() => {
            setShowTooltip(false);
        }, 400);
    };
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);
    const tooltipStyle = {
        visibility: "visible",
        backgroundColor: "black",
        color: "#fff",
        textAlign: "center",
        borderRadius: "5px",
        padding: "5px 10px",
        position: "absolute",
        zIndex: 1,
        bottom: "105%", // Position above the button
        left: "50%",
        transform: "translateX(-50%)",
        opacity: 1,
        transition: "opacity 0.3s"
    };

    const handleCopy = async () => {
        try {

            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.linkBox} onClick={handleClick}>
                <span className={styles.link}>
                    {showTooltip && (<div style={tooltipStyle}>Copied</div>)}
                    {referralLink}</span>
                <button
                    className={styles.copyButton}
                    aria-label="Copy referral link"
                >
                    কপি
                    <FaCopy />
                </button>
            </div>
        </div>
    );
};

export default ReferralLinkBox;
