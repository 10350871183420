import React, { useContext, useEffect, useState } from 'react';
import styles from './ReferralEarning.module.css'; // Import the CSS file
import { dataContext } from '../../../App';
import { fetchReferralHistory } from '../../../services';
import { convertToBengaliNumber } from '../../BannerPart3/helper';

const ReferralEarning = () => {
  const {token} = useContext(dataContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchReferralHistory(token).then((res) => {
      console.log(res);
      setData(res);
    });
  }, [token]);
  let totalReferral = data?.data?.length || 0;
  const totalReferralAmount = (data)? data?.data?.reduce((total, item) => total + item.referral_amount, 0):0;
  return (
    <div className={styles.referralContainer}>
      <div className={styles.referralItem}>
        <div className={styles.icon}><img src={require('./total_refer.png')} style={{width: '42px', height: '42px'}} alt="referral" /></div>
        <div className={styles.text}>
          <div className={styles.label}>টোটাল রেফার</div>
          <div className={styles.value}>{convertToBengaliNumber(totalReferral)}</div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.referralItem}>
        <div className={styles.icon}><img src={require('./earning.png')} style={{width: '42px', height: '42px'}} alt="referral" /></div>
        <div className={styles.text}>
          <div className={styles.label}>টোটাল আর্নিং</div>
          <div className={styles.value}>৳{convertToBengaliNumber(totalReferralAmount)}</div>
        </div>
      </div>
    </div>
  );
};

export default ReferralEarning;