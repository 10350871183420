import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl, dataContext, portal } from "../../App";
import BannerPart3 from "../../components/BannerPart3/BannerPart3";
import Bottombar from "../../components/Bottombar/Bottombar";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import LoginModal from "../../components/LoginModal/LoginModal";
import ContactModal from "../../components/Modal/ContactModal/ContactModal";
import ReferralPolicyModal from "../../components/Modal/ReferralPolicyModal/ReferralPolicyModal";
import RefundModal from "../../components/Modal/RefundModal/RefundModal";
import RewardsModal from "../../components/Modal/RewardsModal/RewardsModal";
import TCModal from "../../components/Modal/TCModal/TCModal";
import Navbar from "../../components/Navbar/Navbar";
import Profile from "../../components/Profile/Profile";
import Subscription from "../../components/Subscription/Subscription";
import Sidebar from "../../components/Sidebar/Sidebar";
import './HomePageV3.css';
import styles from './HomePageV3.module.css'
import homeImg from "./assets/bg.png";
import leaderImg from "./assets/leaderBG.png";
// import LeaderboardWinnerList from "../../components/Leaderboard/LeaderboardWinnerList";
import ErrorSubscriptionModal from "../../components/Modal/ErrorSubscriptionModal/ErrorSubscriptionModal";
import LoadingModal from "../../components/Modal/LoadingModal/LoadingModal";
import RedeemModal from "../../components/Modal/RedeemModal/RedeemModal";
import SubscriptionModal from "../../components/Modal/SubscriptionModal/SubscriptonModal";
import UnSubscriptionModal from "../../components/Modal/UnSubscriptionModal/UnSubscriptionModal";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import Referral from "../../components/Referral/Referral";
import submitRedeemCode from "../../helper/submitRedeemCode";
import ResultPage from "../../components/ResultPage/ResultPage";
import GameSelectionModal from "../../components/Modal/GameSelectionModal/GameSelectionModal";
import SpellingInfoModal from "../../components/Modal/SpellingInfoModal/SpellingInfoModal";
const HomePageV3 = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let bottomBarPageFromSession = searchParams.get("page");
  let tcPage = false;
  if(bottomBarPageFromSession === 'tc') {
    tcPage = true;
    bottomBarPageFromSession = 'home';
  }
  const bottomBarPosition = bottomBarPageFromSession || "home";
  const { token, setToken, playerData, setPlayerData, events } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState(bottomBarPosition);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showGameSelectionModal, setShowGameSelectionModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTCModal, setShowTCModal] = useState(tcPage);
  const [showSpellingInfoModal, setShowSpellingInfoModal] = useState(false);
  const [showReferralPolicyModal, setShowReferralPolicyModal] = useState(false);
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showErrorSubscriptionModal, setShowErrorSubscriptionModal] = useState(false);
  const [showUnSubscriptionModal, setShowUnSubscriptionModal] = useState(false);
  const [toggleToUpdate, setToggleToUpdate] = useState(false);
  const [activeBoard, setActiveBoard] = useState(sessionStorage.getItem('leaderEventId') ? parseInt(sessionStorage.getItem('leaderEventId')) : events[0]?.id);
  const [activeLeaderPage, setActiveLeaderPage] = useState('singleLeaderPage');
  let checkFailedSubscription = (searchParams.get("status") === 'CANCELLED' || searchParams.get("status") === 'FAILED')
  let checkSuccessfulSubscription = (searchParams.get("status") === 'SUCCEEDED')
  const homePageBodyRef = useRef(null);
  let cid = sessionStorage.getItem('cid');
  useEffect(() => {
    if (playerData?.id) {
      let referCode = sessionStorage.getItem('refer_code');
      let hasAttemptedRedeem = sessionStorage.getItem('attempted_redeem');

      if (!playerData?.redeemed_code && referCode && !hasAttemptedRedeem) {
        // Set flag before attempting redeem
        sessionStorage.setItem('attempted_redeem', 'true');

        submitRedeemCode(referCode)
          .then(response => {
            // Handle successful redemption
            sessionStorage.removeItem('refer_code');
            console.log(response)
          })
          .catch(error => {
            sessionStorage.removeItem('refer_code');
            // Handle error case
            console.error('Failed to redeem code:', error);
          });
      }
    }
  }, [playerData]);

  useEffect(() => {
    sessionStorage.removeItem('leaderEventId')
  }, [])
  useEffect(() => {
    searchParams.delete('page')
    setSearchParams(searchParams)
    if (searchParams.get('qcid')) { sessionStorage.setItem('cid', searchParams.get('qcid')); cid = searchParams.get('qcid') }
  }, [bottomBarPosition]);

  useEffect(() => {
    if (checkSuccessfulSubscription) {
      setShowSubscriptionModal(true)
    }
    if (checkFailedSubscription) {
      setShowErrorSubscriptionModal(true)
    }
    if (!token) {
      setPlayerData({});
      let msisdn = null;
      // activityLogSubmission("landing", null);
      // analyticEvent('home', 'home-view')

    } else {
      const config = {
        headers: { Authorization: `Token ${token}` },
      };

      axios
        .get(
          `${baseUrl}/api/clients/participant_profiles/?portal=${portal}${(checkSuccessfulSubscription) ? `&status=SUCCEEDED&reference=${searchParams.get("reference")}` : ''}`,
          config
        )
        .then((response) => {
          if (checkSuccessfulSubscription) {
            analyticEvent('bkash-subscription', 'bkash-subscription-success')
            activityLogSubmission("subscription_success", response.data[0]?.msisdn);
          }
          if (checkFailedSubscription) {
            analyticEvent('bkash-subscription', 'bkash-subscription-failed')
            activityLogSubmission("subscription_failed", response.data[0]?.msisdn);
          }
          searchParams.delete('status')
          setSearchParams(searchParams)
          setPlayerData(response.data[0]);
          if (sessionStorage.getItem("login_event_id")) {
            quizClickHandlerInLogin(parseInt(sessionStorage.getItem("login_event_id")), response.data[0])
            sessionStorage.removeItem("login_event_id")
          }
          let data = response.data[0];

          if (!data?.isSubscribe && (!checkFailedSubscription)) {

          }
        })
        .catch(res => {
          localStorage.setItem("user_msisdn", "");
          localStorage.setItem("AuthToken", "");
          setPlayerData({});
          setToken(null);
        });
    }
  }, [token, activeBoard, toggleToUpdate]);
  let backgroundImg;
  activeBottom === "home"
    ? (backgroundImg = homeImg)
    : (backgroundImg = leaderImg);

  async function quizClickHandlerInLogin(eventId, playerData) {
    console.log(playerData)
    if (!(playerData?.id)) {
      sessionStorage.setItem("login_event_id", eventId);
      setShowLoginModal(true);
    }
    // eventid 34 daily event
    else {
      if (playerData?.isSubscribe) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (true) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          const searchParamsWordly = new URLSearchParams(window.location.search);
          const paramsObjectWordly = Object.fromEntries(searchParamsWordly);
          console.log(paramsObjectWordly,'home')
          axios
            .post(
              `${baseUrl}/wordly/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": "Wordly",...paramsObjectWordly },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              if (data?.redirectURL) {
                console.log(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
              }
            });
        }
      }
    }
  }

  return (
    <div
      className={styles.homePageBody} ref={homePageBodyRef}
      style={{ background: '#f5f5f5' }}
    >
      <Sidebar
        props={{
          showSidebar,
          setShowSidebar,
          setShowLoginModal,
          showLoginModal,
          activeBottom,
          setActiveBottom,
          showContactModal,
          setShowContactModal,
          showTCModal,
          setShowTCModal,
          showReferralPolicyModal,
          setShowReferralPolicyModal,
          showRefundModal,
          setShowRefundModal,
          showRewardsModal,
          setShowRewardsModal,
        }}
      ></Sidebar>
      <div className={styles.fixedNavbar}>
        <Navbar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
      </div>

      <div className={styles.scrollableContent} ref={homePageBodyRef}>
        {activeBottom === "home" && (
          <BannerPart3
            props={{ setShowSpellingInfoModal,setShowLoginModal,setShowGameSelectionModal, showLoginModal, setShowLoadingModal,activeBottom,setActiveBottom, showLoadingModal, showRedeemModal, setShowRedeemModal, quizClickHandlerInLogin }}
          ></BannerPart3>
        )}
        {(activeBottom === "leader") && (
          <Leaderboard
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            activeBoard={activeBoard} setActiveBoard={setActiveBoard} setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
          ></Leaderboard>
        )}
        {activeBottom === "profile" && (
          <Profile
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            setActiveBottom={setActiveBottom}
          ></Profile>
        )}
        {activeBottom === "referral" && (
          <Referral
            props={{ setShowLoginModal, showLoginModal, setShowLoadingModal, showLoadingModal }}
          ></Referral>
        )}
        {activeBottom === 'rewards' && (
          <RewardsModal
            showRewardsModal={showRewardsModal}
            setShowRewardsModal={setShowRewardsModal}
          ></RewardsModal>
        )}
        {activeBottom === "subscription" && (
          <Subscription
            quizClickHandler={quizClickHandlerInLogin}
          ></Subscription>
        )}
        {activeBottom === 'help' && (
          <ContactModal
            showContactModal={showContactModal}
            setShowContactModal={setShowContactModal}
            setShowRefundModal={setShowRefundModal}
          ></ContactModal>
        )}
        {activeBottom === 'tc' && (
          <TCModal
            showTCModal={showTCModal}
            setShowTCModal={setShowTCModal}
            showRefundModal={showRefundModal}
            setShowRefundModal={setShowRefundModal}
          ></TCModal>
        )}
        {activeBottom === 'result' && (
          <ResultPage quizClickHandler={quizClickHandlerInLogin}
            setActiveBottom={setActiveBottom} setActiveBoard={setActiveBoard}></ResultPage>
        )}
      </div>

      <div className={styles.fixedBottombar}>
        <Bottombar
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
          setShowLoginModal={setShowLoginModal}
          activeLeaderPage={activeLeaderPage}
          setActiveLeaderPage={setActiveLeaderPage}
          setShowShobDekho={() => { }}
        />
      </div>
      {showGameSelectionModal && (
        <GameSelectionModal
          showGameSelectionModal={showGameSelectionModal}
          setShowGameSelectionModal={setShowGameSelectionModal}
          quizClickHandlerInLogin={quizClickHandlerInLogin}
        ></GameSelectionModal>
      )}
      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
        ></LoginModal>
      )}
      {showLoadingModal && (
        <LoadingModal
          showLoadingModal={showLoadingModal}
          setShowLoadingModal={setShowLoadingModal}
        ></LoadingModal>
      )}
      {showRedeemModal && (
        <RedeemModal
          showRedeemModal={showRedeemModal}
          setShowRedeemModal={setShowRedeemModal}
          setShowLoadingModal={setShowLoadingModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
        ></RedeemModal>
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
        ></SubscriptionModal>
      )}
      {showErrorSubscriptionModal && (
        <ErrorSubscriptionModal
          showErrorSubscriptionModal={showErrorSubscriptionModal}
          setShowErrorSubscriptionModal={setShowErrorSubscriptionModal}
        ></ErrorSubscriptionModal>
      )}
      {showSpellingInfoModal && (
        <SpellingInfoModal
          showSpellingInfoModal={showSpellingInfoModal}
          setShowSpellingInfoModal={setShowSpellingInfoModal}
        ></SpellingInfoModal>
      )}
      {showUnSubscriptionModal && (
        <UnSubscriptionModal
          showUnSubscriptionModal={showUnSubscriptionModal}
          setShowUnSubscriptionModal={setShowUnSubscriptionModal}
        ></UnSubscriptionModal>
      )}
      {showReferralPolicyModal && (
        <ReferralPolicyModal
          showReferralPolicyModal={showReferralPolicyModal}
          setShowReferralPolicyModal={setShowReferralPolicyModal}
        ></ReferralPolicyModal>
      )}
      {showRewardsModal && (
        <RewardsModal
          showRewardsModal={showRewardsModal}
          setShowRewardsModal={setShowRewardsModal}
        ></RewardsModal>
      )}
      {showRefundModal && (
        <RefundModal
          showRefundModal={showRefundModal}
          setShowRefundModal={setShowRefundModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate} setShowUnSubscriptionModal={setShowUnSubscriptionModal}
        ></RefundModal>
      )}
    </div>
  );
};

export default HomePageV3;
