import { useEffect, useState } from 'react'
import './RotatingBox.css'
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Observer } from "gsap/Observer";

gsap.registerPlugin(Observer);
function RotatingBox({ props }) {
  const { events, quizClickHandler } = props;
  const repeat = (arr, n) => [].concat(...Array(n).fill(arr));
  let repeatationTime = 0;
  let repeatEvents = [];
  if (events.length === 1) { repeatationTime = 6 }
  if (events.length === 2) { repeatationTime = 3 }
  if (events.length === 3) { repeatationTime = 2 }
  if (events.length) { repeatEvents = repeat(events, repeatationTime) }
  if (events.length === 4) {
    repeatEvents = [...events];
    repeatEvents.splice(2, 0, events[0]);
    repeatEvents.splice(4, 0, events[1]);
    console.log(events,repeatEvents)
  }

  // console.log(repeatEvents)
  const carouselBox = useRef();

  useGSAP(() => {
    // gsap code here...
    // gsap.to(".carouselBox", { rotateX:"180",duration:1,ease:'none'});
    gsap.to(".carouselBox", { rotateX: "-=360", duration: 24, ease: 'none', repeat: -1 });
    function runningSlowlyPos() {
      gsap.to(".carouselBox", { rotateX: "+=360", duration: 24, ease: 'none', repeat: -1 });
    }
    function runningSlowlyNeg() {
      gsap.to(".carouselBox", { rotateX: "-=360", duration: 24, ease: 'none', repeat: -1 });
    }
    function upOperation() {
      gsap.to(".carouselBox", { rotateX: "+=60", duration: .3, ease: 'none', onComplete: runningSlowlyPos });
    }
    function downOperation() {
      gsap.to(".carouselBox", { rotateX: "-=60", duration: .3, ease: 'none', onComplete: runningSlowlyNeg });
    }

    Observer.create({
      target: '.carouselBox', // can be any element (selector text is fine)
      type: "wheel,touch,pointer", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
      onUp: () => upOperation(),
      onDown: () => downOperation(),
    });

  });
  return (
    <>
      <div className="bannerBox">
        <div className="carouselBox" ref={carouselBox}>
          {repeatEvents.map((event,index) => (
            <div key={index} className={`singleCard card${index+1}`}><img src={event.banner_url} alt="" srcSet="" draggable="false" onClick={() => quizClickHandler(event.id)} /></div>
          ))}
        </div>
      </div>
    </>
  )
}

export default RotatingBox