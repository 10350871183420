import React from 'react';
import styles from './EventScore.module.css';
import { convertToBengaliNumber } from '../../BannerPart3/helper';

const EventScore = ({ events, playerLeaderboardData }) => {
  const getPlayerDataForEvent = (eventId) => {
    return playerLeaderboardData?.find(data => data.event_id === eventId) || {
      User_Rank: '-',
      score: 0,
      round_number: 0
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>ইভেন্ট স্কোর</div>
      <div className={styles.gridContainer}>
        {events.map((event) => {
          console.log(event.id);
          console.log(parseInt(event.id) === 134);
          const playerData = getPlayerDataForEvent(event.id);
          let scoreDiv = (parseInt(event.id) === 134) ? `${convertToBengaliNumber(playerData.score)}` : `${convertToBengaliNumber(playerData.score)}/${convertToBengaliNumber(event.question_set)}`;
          console.log(scoreDiv)

          return (
            <div key={event.id} className={styles.card}>
              <div className={styles.eventTitle}>{event.events}</div>
              <div className={styles.scoreContainer}>
                <div className={styles.score}>
                {scoreDiv}
                </div>
                <div className={styles.rank}>
                  Rank- {playerData.User_Rank === 0 ? '-' : playerData.User_Rank}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default EventScore;
