import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import styles from './RedeemModal.module.css'
import '../../../assets/css/modal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
const RedeemModal = ({ showRedeemModal, setShowRedeemModal, setShowLoadingModal,setToggleToUpdate,toggleToUpdate }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  let referCode = sessionStorage.getItem('refer_code')
  const [redeemCode, setRedeemCode] = useState(referCode || "");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false)

  const config = {
    headers: { Authorization: `Token ${token}` },
  };
  function hideModalOperation() {
    setShowRedeemModal(false)
    setShowLoadingModal(true)
    const searchParamsWordly = new URLSearchParams(window.location.search);
    const paramsObjectWordly = Object.fromEntries(searchParamsWordly);
    console.log(paramsObjectWordly,'redeem')
    axios
      .post(
        `${baseUrl}/wordly/subscription/create/`,
        { portal: portal, qcid: sessionStorage.getItem("cid") , "service_type": "Wordly",...paramsObjectWordly},
        config
      )
      .then((response) => {
        let data = response.data;
        setShowLoadingModal(false)
        console.log(data);
        if (data?.redirectURL) {
          // analyticEvent('redirect', 'redirect-bkash-payment')
          window.location.replace(`${data?.redirectURL}`);
        }
      });
  }
  function submitOperation() {
    console.log(redeemCode)
    axios
      .put(
        `${baseUrl}/api/redeemedCode/?portal=${portal}`,
        { code: redeemCode,portal:portal },
        config
      )
      .then((response) => {
        activityLogSubmission("redeem-submit");
        let data = response.data;
        // console.log(data);
        setToggleToUpdate(!toggleToUpdate);
        hideModalOperation()
      }).catch(error => {
        if (error?.response?.data?.error) {
          setShowError(true)
        }
      })
  }




  return (
    <>
      <Modal show={showRedeemModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header >
          <Modal.Title>Redeem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className={`inputSpan`}>
            {" "}
            <input
              type="text"
              maxLength={12}
              minLength={12}
              onKeyUp={(e) => {
                setRedeemCode(e.target.value);
                setShowError(false)
              }}
              id="redeemCode"
              name="redeemCode"
              placeholder="Redeem Code"
              className={styles.inputBox}
              value={redeemCode}
            />{" "}
          </span>
          {showError && <div style={{ color: "#ff0000", textAlign: 'center', fontWeight: 600 }}>*Invalid Code</div>}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${styles.redeemButton}`}
            onClick={hideModalOperation}
          >Skip
          </button>
          <button
            type="button"
            className={`btn ${styles.redeemButton}`}
            onClick={submitOperation}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RedeemModal