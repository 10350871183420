import axios from "axios";
import { baseUrl, portal } from "../App";

export default async function submitRedeemCode(code) {

  const config = {
    headers: { Authorization: `Token ${localStorage.getItem("AuthToken")}` },
  };

  try {
    const response = await axios.put(
      `${baseUrl}/api/redeemedCode/`,
      { code: code, portal: portal },
      config
    );
    return response;
  } catch (error) {
    sessionStorage.removeItem('refer_code');
    throw error; // Re-throw the error so the caller can handle it
  }
}
