import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import './TCModal.css'
import styles from './TCModal.module.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';

const TCModal = ({ showTCModal, setShowTCModal,setShowRefundModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowTCModal(false)
  }

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("termsConditions", msisdn);
    analyticEvent('termsConditions', 'termsConditions-view')
    pushCleverTapEvent('CheckIn-TC');
  }, [])

  return (
    <>
      <div className={styles.tcModal}>
        <div className={styles.tcModalHeader}>
          <div className="headerTitle" style={{marginBottom: '8px'}}>
            <img src={require('./tc.png')} className={styles.tcModalIcon} alt="terms" />
            <span className={styles.tcModalTitle}>নিয়ম ও শর্তাবলী</span>
          </div>
          <p className={styles.tcModalDescription}>
            Wordly তে আপনাকে স্বাগতম! WORDLY একটি সামাজিক গেমিং প্লাটফর্ম । Wordly তে বাংলা ও ইংরেজি শব্দ মেলানোর মজার গেম খেলে নিজের শব্দ দক্ষতা যাচাই করুন আর দৈনিক ও সাপ্তাহিক আকর্ষণীয় সব পুরষ্কার জিতে নিন ।
          </p>
          <p className={styles.tcModalDescription}>
            খেলা শুরু করার আগে, অনুগ্রহ করে আপনি নিচের নিয়ম ও শর্তাবলী মনোযোগ সহকারে পড়ুন এবং বুঝুন । এই গেম খেলায় অংশগ্রহণ করে, আপনি নিম্নলিখিত শর্তাবলী মেনে চলতে সম্মত হোন ।
          </p>
        </div>

        <div className={styles.tcModalContent}>
          <Accordion className={styles.accordion}>
            <Accordion.Item eventKey="0" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>খেলার যোগ্যতা</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                Wordly গেমটি সকল বয়সের এবং জাতীয়তার বাক্তিদের জন্য উন্মুক্ত । কিন্তু, যে কোনো ধরনের প্রতারণা, বাহ্যিক সাহায্যের ব্যবহার, বা Wordly প্ল্যাটফর্মের সাথে হেরফের করার প্রচেষ্টা অবিলম্বে অযোগ্যতার কারণ হবে। অযোগ্য অংশগ্রহণকারীরা কোনো পুরস্কার বা পুরস্কারের জন্য যোগ্য হবে না। আয়োজক সত্তার কর্মচারী এবং পরিবারের সদস্যরা অংশগ্রহণের যোগ্য নন।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>লিংক</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <a href="https://wordly.quizard.live/" target="_blank" rel="noopener noreferrer">https://wordly.quizard.live/</a>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>কীভাবে যোগদান করবেন</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                বিকাশ পেমেন্ট এর মাধ্যমে সাপ্তাহিক ৩০ টাকা চার্জে ব্যবহারকারীরা WORDLY তে সাবস্ক্রিপশন করতে পারবেন । একটি বিকাশ নাম্বার এর বিপরীতে একাধিক অ্যাকাউন্ট এর সাবস্ক্রিপশন নিতে পারবেন ।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>WORDLY খেলার নিয়ম</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <ul>
                  <li>বাংলা ও ইংরেজি সেগমেন্টে WORDLY তে শব্দ মিল খেলার সুযোগ থাকবে এবং দৈনিক সর্বোচ্চ ২ বার খেলা যাবে ।</li>
                  <li>এলোমেলো কিছু অক্ষর থাকবে, এদের সাজিয়ে একটি অর্থপূর্ণ শব্দ পাওয়া যাবে । কোনো অক্ষর তার সঠিক স্থানে থাকলে সবুজ রেখা দ্বারা সেটি অংশগ্রহণকারীর দৃষ্টিগোচর হবে ।</li>
                  <li>অংশগ্রহণকারীরা প্রতিটি সঠিক শব্দের জন্য ১ পয়েন্ট অর্জন করবে। প্রতি রাউন্ড এর সময় সীমা ১৮০ সেকেন্ড ।</li>
                  <li>প্রতি রাউন্ড এ অংশগ্রহণকারী গণ নির্দিষ্ট সময়ে যে কয়টি সঠিক শব্দ মিল করবে, সেটিই হবে তার ঐ রাউন্ড এর টোটাল স্কোর ।</li>
                  <li>দুটি রাউন্ড এর মধ্যে যে রাউন্ড এর স্কোর বেশি হবে, সেটিই ফাইনাল স্কোর হিসেবে গণনা করা হবে ।</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>স্পেলিং বি খেলার নিয়ম</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <ul>
                  <li>স্পেলিং বি সেগমেন্টে ইংরেজিতে ৭ টি লেটার দেয়া থাকবে। এখান থেকে শব্দ মিল করার সুযোগ থাকবে এবং দৈনিক সর্বোচ্চ ২ বার খেলা যাবে।</li>
                  <li>অংশগ্রহণকারীরা প্রতিটি সঠিক শব্দের জন্য ১ পয়েন্ট অর্জন করবে। প্রতি রাউন্ড এর সময় সীমা ১৮০ সেকেন্ড।</li>
                </ul>
                ** ওয়ার্ডলি এবং স্পেলিং বি সেগমেন্ট থেকে যেটার স্কোর বেশি হবে, সেটিই ফাইনাল স্কোর হিসেবে গণনা করা হবে।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>বিজয়ীদের পুরস্কার</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <ul>
                  <li><b>দৈনিক পুরস্কার:</b> প্রতিদিনের অংশগ্রহণকারীদের মধ্যে সেরা ৩০ জন সঠিক উত্তর প্রদানকারী কে ১০ টাকা বিকাশ ক্যাশ ইন করা হবে |</li>
                  <li>
                    <b>সাপ্তাহিক পুরস্কার:</b> প্রতি সপ্তাহের ৩ জন সর্বোচ্চ স্কোর ধারী অংশগ্রহণকারী কে ৩০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে ।
                    <ul>
                      <li>প্রথম পুরস্কার - ১৫০ টাকা</li>
                      <li>দ্বিতীয় পুরস্কার - ১০০ টাকা</li>
                      <li>তৃতীয় পুরস্কার - ৫০ টাকা</li>
                    </ul>
                  </li>
                </ul>
                ** আয়োজক সত্তা তার বিবেচনার ভিত্তিতে পুরস্কার পরিবর্তন বা প্রতিস্থাপন করার অধিকার সংরক্ষণ করে।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>বিজয়ী হবার নিয়মাবলী</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <ul>
                  <li>নির্দিষ্ট সময় সীমার মধ্যে সর্বাধিক সঠিক শব্দ প্রদানকারী দের মেধাক্রম অনুসারে পুরস্কৃত করা হবে ।</li>
                  <li>দৈনিক পুরস্কার পাওয়ার জন্য মিনিমাম স্কোর হতে হবে ৩০।</li>
                  <li>সাপ্তাহিক পুরস্কার পাওয়ার জন্য মিনিমাম স্কোর হতে হবে ২৮০ ।</li>
                  <li>বিজয়ী হওয়ার জন্য অংশগ্রহণকারীকে পেইড সাবস্ক্রাইবার হতে হবে।</li>
                  <li>ক্যাম্পেইন চলাকালীন সময় WORDLY থেকে আনসাবস্ক্রাইব করতে পারবে না । অন্যথায় সর্বোচ্চ স্কোর থাকলেও অযোগ্য হিসেবে ধরা হবে এবং কোনো পুরস্কারের জন্য যোগ্য বিবেচিত হবে না।</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>রেফার কোড ব্যবহারের নিয়ম</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                WORDLY এর মেন্যু বার থেকে Profile (প্রোফাইল) অপশনে গেলে আপনার রেফার কোড টি পেয়ে যাবেন ।
                <ul>
                  <li>আপনার রেফারেল কোডটি শেয়ার করুন । আপনার রেফার কোড ব্যবহার করে কেউ যদি সাবস্ক্রিপশন করে, তাহলে প্রতি সফল সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকা ক্যাশ ইন ।</li>
                  <li>কারো শেয়ারকৃত রেফার কোড ব্যবহার করে সাবস্ক্রিপশন করলে, ১ম সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকার ক্যাশ ব্যাক।</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>ক্যাম্পেইন এর সময়সীমা</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                প্রতি বৃহস্পতিবার থেকে বুধবার পর্যন্ত ।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>গোপনীয়তা</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                গেমটি খেলার সময় সংগৃহীত অংশগ্রহণকারীদের তথ্য শুধুমাত্র খেলা পরিচালনার উদ্দেশ্যে এবং প্রযোজ্য হলে পুরস্কার প্রদানের উদ্দেশ্যে ব্যবহার করা হবে। ব্যক্তিগত তথ্য পূর্ব সম্মতি ছাড়া তৃতীয় পক্ষের সাথে ভাগ করা হবে না।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>বুদ্ধিবৃত্তিক সম্পত্তি</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                প্রশ্ন, উত্তর এবং ছবি সহ সমস্ত কুইজের বিষয়বস্তু হল আয়োজক সত্তার বৌদ্ধিক সম্পত্তি। অননুমোদিত প্রজনন বা বিতরণ নিষিদ্ধ।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11" className={styles.accordionItem}>
              <Accordion.Header className={styles.accordionButton}>দায়</Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                গেমটি খেলার সময় ঘটতে পারে এমন কোনও প্রযুক্তিগত সমস্যা, বাধা বা ত্রুটির জন্য আয়োজক সত্তা দায়বদ্ধ নয়। অংশগ্রহণকারীরা যেন তাদের নিজস্ব ঝুঁকিতে খেলে।
              </Accordion.Body>
            </Accordion.Item>

            {((playerData?.isSubscribe) || (playerData?.isSubscribeTournament)) && <Accordion.Item eventKey="12">
              <Accordion.Header>কিভাবে আন্সাবস্ক্রাইব করবেন</Accordion.Header>
              <Accordion.Body>
              আন্সাবস্ক্রাইব করতে ক্লিক করুন <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{setShowRefundModal(true);setShowTCModal(false);}}>এখানে</span>
              </Accordion.Body>
            </Accordion.Item>}
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default TCModal