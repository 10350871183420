import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import styles from './SpellingInfoModal.module.css'
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const SpellingInfoModal = ({ showSpellingInfoModal, setShowSpellingInfoModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowSpellingInfoModal(false)
  }

  useEffect(() => {
    // let msisdn = null;

    // if (playerData?.id) {
    //   msisdn = playerData?.msisdn;
    // }
    // activityLogSubmission("contacts", msisdn);
    // analyticEvent('contact', 'contact-view')
  }, [])


  return (
    <>
      <Modal show={showSpellingInfoModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{ color: '#232323' }}>স্পেলিং বি খেলার নিয়ম</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>স্পেলিং বি সেগমেন্টে ইংরেজিতে ৭ টি লেটার  দেয়া থাকবে। এখান থেকে শব্দ মিল করার সুযোগ থাকবে এবং দৈনিক সর্বোচ্চ ২ বার খেলা যাবে।</li>
            <li>অংশগ্রহণকারীরা প্রতিটি সঠিক শব্দের জন্য ১ পয়েন্ট অর্জন করবে। প্রতি রাউন্ড এর সময় সীমা ১৮০ সেকেন্ড।</li>
          </ul>

          <span style={{paddingLeft:'16px'}}> *** ওয়ার্ডলি এবং স্পেলিং বি সেগমেন্ট থেকে যেটার  স্কোর বেশি হবে, সেটিই ফাইনাল স্কোর হিসেবে গণনা করা হবে ।
          </span>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SpellingInfoModal