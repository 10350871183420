import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "../../assets/css/modal.css";
import "./LoginModal.module.css";
import axios from "axios";
import { baseUrl, portal, dataContext } from "../../App";
import activityLogSubmission from "../../helper/activitylog";
import { useNavigate, useSearchParams } from "react-router-dom";
import analyticEvent from "../../helper/gaEvent";
import styles from "./LoginModal.module.css";
import xImg from './assets/x.png'
import loginIcon from './assets/login_icon.png'

const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  activeBottom,
  setActiveBottom,
}) => {
  const { token, setToken, playerData, setPlayerData } =
    useContext(dataContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileNumberOkay, setIsMobileNumberOkay] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  useEffect(() => {
    analyticEvent('login', 'login-view')
  }, [])
  if (activeBottom === "campaign") {
    localStorage.setItem("AuthToken", "");
    localStorage.setItem("user_msisdn", "");
  }
  function checkMobileNumber() {
    var mobilePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (mobileNumber.match(mobilePattern)) {
      return true;
    } else {
      return false;
    }
  }
  function loginOperation() {
    if (checkMobileNumber()) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/api/login/${sessionStorage.getItem("promo_code")?'?promo='+sessionStorage.getItem("promo_code"):''}`, {
          msisdn: mobileNumber.toString().slice(-10),
          portal: portal,
          qcid: sessionStorage.getItem("cid")
        })
        .then((response) => {
          let data = response.data;
          let newToken = response.data.token;
          console.log(data, data?.is_verified);
          localStorage.setItem("AuthToken", newToken);
          setToken(newToken);
          localStorage.setItem("user_msisdn", mobileNumber.toString().slice(-10));
          activityLogSubmission(
            "login_submit",
            mobileNumber.toString().slice(-10)
          );
          analyticEvent('login', 'login-success')
          setShowLoginModal(false);
          setIsLoading(false);
        });
    } else {
      console.log("error");
      setShowErrorOutline(true);
      analyticEvent('login', 'login-error')
    }
  }

  function hideModalOperation() {
    setShowLoginModal(false);
    if (activeBottom === "profile") {
      if (!playerData?.id) {
        setActiveBottom("home");
      }
    }
    if (activeBottom === "campaign") {
      navigate("../");
    }
  }
  return (
    <>
      <Modal
        show={showLoginModal}
        centered
        dialogClassName={styles.loginModalDialog}
        contentClassName={styles.loginModalContent}
        onHide={hideModalOperation}
        backdrop="static"
      >
        <img src={xImg} alt="logo" className={styles.xImg} onClick={() => {
          sessionStorage.removeItem("login_event_id");
          hideModalOperation();
        }} />
        <Modal.Body className={styles.loginModalBody}>
          <img src={loginIcon} alt="logo" className={styles.loginIcon} />
          <div className={styles.infoContainer}>
            <span className={styles.infoText}>আপনার মোবাইল নম্বর দিন</span>
            <div className={styles.inputContainer}>
              <span>মোবাইল নম্বর</span>
              <span className={`${styles.inputSpan} ${showErrorOutline && styles.errorOutline}`}>
                <input
                  type="tel"
                  maxLength={11}
                  onKeyUp={(e) => {
                    setMobileNumber(e.target.value);
                    setIsMobileNumberOkay(checkMobileNumber());
                  }}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                    setIsMobileNumberOkay(checkMobileNumber());
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && loginOperation()}
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="মোবাইল নম্বর দিন"
                  className={styles.inputFieldMobile}
                />{" "}
              </span>
              <button
                type="button"
                className={`btn ${styles.numberSubmit}`}
                onClick={loginOperation}
                disabled={!isMobileNumberOkay}
                style={{ backgroundColor: !isMobileNumberOkay ? "#757575" : "#5B1674",color: !isMobileNumberOkay ? "#cecece" : "#fff" }}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "প্রবেশ করুন"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
