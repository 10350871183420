import Campaign from "./pages/Campaign/Campaign";
import CampaignOnlyLoginDiv from "./pages/Campaign/CampaignOnlyLoginDiv";
import Campaign2 from "./pages/Campaign2/Campaign2";
import HomePageV3 from "./pages/HomePageV3/HomePageV3";
import QuizPage from "./pages/QuizPage/QuizPage";

export const routes = [
  {
    path: "/",
    element: <HomePageV3 />,
  },
  {
    path: "/campaign/",
    element: <CampaignOnlyLoginDiv campaignNumber={1} />,
  },
  {
    path: "/campaign2/",
    element: <Campaign campaignNumber={2} />,
  },
  {
    path: "/campaign3/",
    element: <Campaign campaignNumber={3} />,
  },
  {
    path: "/campaign4/",
    element: <Campaign2 campaignNumber={4} />,
  },
  {
    path: "/quiz/",
    element: <QuizPage />,
  },
];