import React, { useContext } from 'react'
import Modal from "react-bootstrap/Modal";
import "../../../assets/css/modal.css";
import '../../../assets/css/modal.css'
import styles from "./GameSelectionModal.module.css";
import { dataContext } from '../../../App';
const GameSelectionModal = (props) => {
  const { playerData,events } = useContext(dataContext);
  const { showGameSelectionModal, setShowGameSelectionModal, quizClickHandlerInLogin } = props;

  function hideModalOperation() {
    setShowGameSelectionModal(false)
  }
  return (
    <>
      <Modal
        show={showGameSelectionModal}
        centered
        dialogClassName={styles.gameSelectionModalDialog}
        contentClassName={styles.gameSelectionModalContent}
        onHide={hideModalOperation}
        // backdrop="static"
      >
        <img src={require("./x.png")} alt="logo" className={styles.xImg} onClick={() => {
          hideModalOperation();
        }} />
        <Modal.Body className={styles.gameSelectionModalBody}>
          <img src={require("./logo.png")} alt="logo" className={styles.logo} />
          <div className={styles.infoContainer}>
            <span className={styles.infoText}>আপনি কোন ল্যাঙ্গুয়েজে 
            খেলতে চান?</span>
          </div>
          <div className={styles.gameSelectionContainer}>
            <div className={styles.gameSelectionItem} onClick={() => {
              quizClickHandlerInLogin(events[1]?.id,playerData);
              hideModalOperation();
            }}>
              <span>বাংলা</span>
              <img src={require("./arrow.png")} alt="arrow" className={styles.arrowImg} />
            </div>
            <div className={styles.gameSelectionItem} onClick={() => {
              quizClickHandlerInLogin(events[0]?.id, playerData);
              hideModalOperation();
            }}>
              <span>English</span>
              <img src={require("./arrow.png")} alt="arrow" className={styles.arrowImg} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GameSelectionModal