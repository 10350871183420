import React, { useContext, useState, useEffect, useRef } from "react";
import { baseUrl, dataContext } from "../../App";
import "./Profile.css";
import avatarImg from "../../assets/img/leaderboard/avatar.png";
import scoreBgImg from "../../assets/img/v2/score_bg.png";
import ProfileEditModal from "../ProfileEditModal/ProfileEditModal";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import { FaShare } from "react-icons/fa6";
import { pushCleverTapEvent } from "../../helper/clevertapFunction";
import ProfileUpper from "./ProfileUpper/ProfileUpper";
import EventScore from "./EventScore/EventScore";
import ReferralHistory from "./ReferralHistory/ReferralHistory";
import PlayingHistory from "../BannerPart3/PlayingHistory/PlayingHistory";
import SubscriptionStatus from "../Subscription/SubscriptionStatus/SubscriptionStatus";

const Profile = ({ showLoginModal, setShowLoginModal, setShowReferModal, setActiveBottom, quizClickHandler }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showReferralHistory, setShowReferralHistory] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef(null); // To keep track of the timeout

  const handleClick = () => {
    // Clear any previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Show the tooltip
    setShowTooltip(true);

    // Set a new timeout to hide the tooltip after 1000ms
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 400);
  };

  // Clear the timeout if the component unmounts to avoid memory leaks
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const tooltipStyle = {
    visibility: "visible",
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px 10px",
    position: "absolute",
    zIndex: 1,
    bottom: "125%", // Position above the button
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 1,
    transition: "opacity 0.3s"
  };
  useEffect(() => {
    if (playerData?.id) {
    } else {
      setShowLoginModal(true);
    }
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("profile", msisdn);
    analyticEvent('profile', 'profile-view')
    pushCleverTapEvent('CheckIn-Profile');
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventId: event.id,
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
        rank: userLeaderboard[0]?.User_Rank,
      };
    } else {
      return {
        eventId: event.id,
        eventTitle: event.events,
        score: "-",
        rank: "-",
      };
    }
  });

  return (
    <>
      <div className="profileWrapper">
        <ProfileUpper playerData={playerData} setShowProfileEditModal={setShowProfileEditModal} />
        <EventScore events={events} playerLeaderboardData={playerData?.leaderboard} />
        <div className="subsCription" style={{ padding: '24px 16px' }}>
          <SubscriptionStatus
            isSubscribe={playerData?.isSubscribe}
            isSubscribeTournament={playerData?.isSubscribeTournament}
          />
        </div>
        <ReferralHistory />
        <PlayingHistory events={events} playerData={playerData} quizClickHandler={quizClickHandler} />
      </div>
      {/* <div className="profileWrapper">
        <div className="borderWrapper">
          <div className="outerBoxLeft"></div>
          <div className="outerBoxRight"></div>
        </div>
        <div className="profileHeaderCardWrapper">
          <div className="profileCardHeader"> প্রোফাইল </div>
        </div>
        <div className="profileUserBody mt-3">
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="profileAvatar"
            alt=""
            srcSet=""
          />

          <div className="profileUsername">
            {playerData?.name ? playerData.name : "ইউজারনেম"}
          </div>
          <div className="profileMobilenumber">
            {playerData?.id
              ? `0${playerData.msisdn.toString().slice(-10)}`
              : "-"}
          </div>
          {playerData.id && (
            <div
              className="editBtn"
              onClick={() => {
                setShowProfileEditModal(true);
                analyticEvent('profile', 'profile-edit-view')
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </div>
          )}
        </div>
        <div className="profileStatusBody mt-3 mb-1">
          {playerData?.id && <div className="profileStatus mb-1">
            Referral Code:{" "}
            {playerData?.id ? (
              ((playerData?.code) ? <>
                <span className='copyCodeSpanProfile' style={{ position: 'relative', display: 'inline-block' }} onClick={() => { navigator.clipboard.writeText(playerData?.code); handleClick(); }}>&nbsp; {playerData?.code} &nbsp; <i className="fa-solid fa-copy copyIcon"></i>
                  {showTooltip && (
                    <div style={tooltipStyle}>Copied</div>
                  )}
                </span>
                <span> &nbsp; &nbsp; <span onClick={() => { setActiveBottom('referral') }}><FaShare style={{ fontSize: '18px' }} /></span></span>
              </>
                : <span style={{ color: "#f00" }}>&nbsp; -</span>)
            ) : (
              `-`
            )}
          </div>}
          {playerData?.id && <div className="profileStatus mb-1">
            Referral Score:{" "}
            {playerData?.id ? (
              (<span style={{ color: "#EB863D" }}>&nbsp; {playerData?.point} &nbsp;
                <span onClick={() => {
                  setShowReferralHistory(true);
                }} style={{ color: '#EB863D' }}> &nbsp;
                  <u>History</u>
                  &nbsp;
                </span>
              </span>)
            ) : (
              `-`
            )}
          </div>}
          <div className="profileStatus mb-1">
            স্ট্যাটাস:{" "}
            {playerData?.id ? (
              ((playerData?.isSubscribe) ? <span style={{ color: "#a0f34f" }}>সাবস্ক্রাইবড </span> : <span style={{ color: "#f00" }}>আনসাবস্ক্রাইবড </span>)
            ) : (
              `-`
            )}
          </div>
          <div className="profileLastPayment">
            সর্বশেষ পেমেন্ট: &nbsp; {playerData?.payment_history?.last_payment ? (
              (<span style={{ color: "#fff" }}>&nbsp; {playerData?.payment_history?.last_payment}</span>)
            ) : (
              `-`
            )}
          </div>
          <div className="profileNextPayment" >
            পরবর্তী পেমেন্ট: &nbsp; {playerData?.payment_history?.next_payment ? (
              (<span style={{ color: "#fff" }}>&nbsp; {playerData?.payment_history?.next_payment}</span>)
            ) : (
              `-`
            )}
          </div>
        </div>
        <div className="profileScore">
          <div className="wrapperTitle" style={{ paddingLeft: "5%" }}>
            ইভেন্ট স্কোর
          </div>
          <div className="scoreBox">
            {userScore.map((singleScore) => (
              <div className="singleScoreDiv" key={singleScore.eventId}>
                <div className="">{singleScore.eventTitle}</div>
                <div className="">#{singleScore.rank}</div>
                <div className="">স্কোর: {singleScore.score}</div>
              </div>
            ))}
          </div>
        </div>
        <span className="dotDesign">...</span>
      </div> */}
      {showProfileEditModal && (
        <ProfileEditModal
          showProfileEditModal={showProfileEditModal}
          setShowProfileEditModal={setShowProfileEditModal}
        ></ProfileEditModal>
      )}
      {showReferralHistory && (
        <ReferralHistory
          showReferralHistory={showReferralHistory}
          setShowReferralHistory={setShowReferralHistory}
          setShowLoginModal={setShowLoginModal}
        ></ReferralHistory>
      )}
    </>
  );
};

export default Profile;
